import {
  IColumnMetaInfo,
  ILinePoint,
  IDiscretePoint,
  IRange,
  ILineDiscretePoint,
  ServerDiscretePoint,
} from '@discngine/moosa-models';
import { v4 as uuid } from 'uuid';

export function pointsCompare(p1: IDiscretePoint, p2: IDiscretePoint) {
  return p1.x === p2.x ? 0 : p1.x > p2.x ? 1 : -1;
}

export function getValueFromLinearApprox(xVal: number, points: IDiscretePoint[]): number {
  const sortedPoints = [...points].sort(pointsCompare);
  const num = sortedPoints.length - 1;

  for (let i = 0; i < num; i++) {
    const point = sortedPoints[i];
    const next = sortedPoints[i + 1];

    if (point.x === xVal) {
      return point.y;
    }

    if (point.x <= xVal && xVal < next.x) {
      // @ts-ignore
      const fraction = (xVal - point.x) / (next.x - point.x);

      return point.y + (next.y - point.y) * fraction;
    }
  }

  return sortedPoints[num].y;
}

export function toLine(points: IDiscretePoint[], range: IRange): ILineDiscretePoint[] {
  const line = points.reduce<IDiscretePoint[]>((acc, point, index) => {
    const fragment: IDiscretePoint[] = [
      {
        ...point,
        // to differentiate from original point
        id: uuid(),
        x: index,
        y: 0,
        mark: false,
        originalPointIndex: point.isNotInFunction ? undefined : point.originalPointIndex, // to not allow to move the point
      },
      {
        ...point,
        x: index,
        originalPointIndex: point.isNotInFunction ? undefined : point.originalPointIndex,
      },
      {
        ...point,
        // to differentiate from original point
        id: uuid(),
        x: index,
        y: 0,
        mark: false,
        originalPointIndex: point.isNotInFunction ? undefined : point.originalPointIndex,
      },
    ];

    acc.push(...fragment);

    return acc;
  }, []);

  return extendLineToRange(line, range);
}

export function convertToServerNeededFormat(
  points: IDiscretePoint[]
): ServerDiscretePoint[] {
  return points
    .filter((point) => !point.isNotInFunction)
    .map(({ x, y }) => ({
      x,
      y,
    }));
}

/**
 * this function mutate points array
 */
export function extendLineToRange(
  points: IDiscretePoint[],
  range: IRange
): ILineDiscretePoint[] {
  const first = points[0];
  const last = points[points.length - 1];

  if (range.min < first.x) {
    points.unshift({
      id: uuid(),
      x: range.min,
      y: first.y,
    });
  }

  if (range.max > last.x) {
    points.push({
      id: uuid(),
      x: range.max,
      y: last.y,
    });
  }

  return points;
}

/**
 * To cover a case when statistics.min === statistics.max
 * (A dataset with one point, f. e.)
 * @param val
 */
export function getRangeForSingleVal(val: number): IRange {
  const delta = val === 0 ? 1 : Math.max(1, Math.abs(val / 10));

  return {
    min: val - delta,
    max: val + delta,
  };
}

/**
 * avoid same min and max
 * @param metadata
 */
export function getRangeForInitialization(metadata: IColumnMetaInfo): IRange {
  const min = metadata.statistics?.min || 0;
  const max = metadata.statistics?.max || 0;

  if (min === max) {
    return getRangeForSingleVal(min);
  }

  return { min, max };
}

export function getRangeForLinearApprox(
  points: IDiscretePoint[],
  metadata: IColumnMetaInfo
) {
  const sortedPoints = [...points].sort(pointsCompare);

  const range = {
    min: Math.min(+sortedPoints[0].x, metadata.statistics!.min),
    max: Math.max(+sortedPoints[sortedPoints.length - 1].x, metadata.statistics!.max),
  };

  if (range.max === range.min) {
    return getRangeForSingleVal(range.max);
  }

  return range;
}

export function updateLinePoint(
  points: ILineDiscretePoint[],
  { originalPointIndex, y: newY }: ILineDiscretePoint
): { points: ILineDiscretePoint[] } {
  const pnts = [...points];

  const y = checkYBoundaries(newY);

  const point = pnts.find((apoint) => apoint.originalPointIndex === originalPointIndex!)!;

  point.y = y;

  return { points: pnts };
}

const X_MIN = -1e24;
const X_MAX = 1e24;

export function checkXBoundaries(x: number | string) {
  if (x < X_MIN) {
    return X_MIN;
  }

  if (x > X_MAX) {
    return X_MAX;
  }

  return x;
}

export function checkYBoundaries(y: number) {
  if (y < 0) {
    return 0;
  }

  if (y > 1) {
    return 1;
  }

  return y;
}

const MAX_ERROR = 0.005;

/**
 * split range into segments to minimize error when approximate function by linear intervals
 *
 * @param initialPoints - array of initial points
 * @param fx - function f(x), we expect that f(x) has same sign of first derivative on each interval
 * @param dfx - derivative of f(x)
 */
export function segmentation(
  initialPoints: ILinePoint[],
  fx: (x: number) => number,
  dfx: (x: number) => number
): IDiscretePoint[] {
  const points: ILinePoint[] = [...initialPoints].sort(pointsCompare);

  const rangesStack: [ILinePoint, ILinePoint][] = [];

  for (let i = 0; i < points.length - 1; i++) {
    rangesStack.push([points[i], points[i + 1]]);
  }

  while (rangesStack.length) {
    const [p0, p1] = rangesStack.pop()!;
    const delta = p1.y - p0.y;
    const deltaApprox = dfx(p0.x) * (p1.x - p0.x);

    if (Math.abs(deltaApprox - delta) > MAX_ERROR) {
      const x = (p0.x + p1.x) / 2;
      const middle: ILinePoint = {
        id: uuid(),
        x,
        y: fx(x),
      };

      rangesStack.push([p0, middle]);
      rangesStack.push([middle, p1]);
      points.push(middle);
    }
  }

  return points.sort(pointsCompare);
}
