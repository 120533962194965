import {
  IColumnMetaInfo,
  DesirabilityFunctionRules,
  ILinePoint,
  IRange,
  LinearFunctionParams,
  ServerLinePoint,
} from '@discngine/moosa-models';
import { v4 as uuid } from 'uuid';

import {
  assignIndices,
  getRangeForInitialization,
  getRangeForLinearApprox,
  getValueFromLinearApprox,
  removeIndices,
  toLine,
  updateLinePoint,
} from './rulesUtils';

export const linearFunctionRules: DesirabilityFunctionRules<LinearFunctionParams> = {
  init(metadata: IColumnMetaInfo): LinearFunctionParams {
    const { min, max } = getRangeForInitialization(metadata);

    return {
      points: [
        { x: min, y: 0, originalPointIndex: 0, id: uuid() },
        { x: max, y: 1, originalPointIndex: 1, id: uuid() },
      ],
    };
  },
  getValue(xVal: number, params: LinearFunctionParams): number {
    return getValueFromLinearApprox(xVal, params.points);
  },
  getRange(params: LinearFunctionParams, metadata: IColumnMetaInfo): IRange {
    return getRangeForLinearApprox(params.points, metadata);
  },
  toLine(params: LinearFunctionParams, range: IRange): ILinePoint[] {
    return toLine(params.points, range);
  },
  toServerTemplate(params: LinearFunctionParams): ServerLinePoint[] {
    return removeIndices(params.points);
  },
  fromServerTemplate(points: ServerLinePoint[]): LinearFunctionParams {
    return { points: assignIndices(points) } as LinearFunctionParams;
  },
  movePoint(params: LinearFunctionParams, point: ILinePoint) {
    return updateLinePoint(params.points, point) as LinearFunctionParams;
  },
};
