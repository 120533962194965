import { LoadingSpinner } from '@discngine/moosa-shared-components';
import { Layout } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DatasetSelector from '../pages/DatasetSelector/DatasetSelector';
import MultiDimensionsAnalysis from '../pages/MultiDimensionsAnalysis/MultiDimensionsAnalysis';

import Header from './Header';
import './Main.less';

const Moosa3dLazy = React.lazy(() => import('../pages/Viewer3d/Moosa3d'));

const Main: React.FC = () => {
  return (
    <Layout id="mainContainer">
      <Layout.Header className="af-header">
        <Header />
      </Layout.Header>
      <Layout.Content>
        <div id="mainContent">
          <React.Suspense fallback={<LoadingSpinner />}>
            <Switch>
              <Route
                path="/data/:id"
                render={(routeProps) => (
                  <MultiDimensionsAnalysis tableId={routeProps.match.params.id} />
                )}
              />
              <Route path="/moosa-3d">
                <Moosa3dLazy />
              </Route>
              <Route path="/">
                <DatasetSelector />
              </Route>
            </Switch>
          </React.Suspense>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Main;
