import { decisionTreeConfigSlice } from '@discngine/moosa-store/decisionTreeConfig';
import {
  decisionTreesWithVersionsSlice,
  getDecisionTreesDataProvider,
} from '@discngine/moosa-store/decisionTrees';
import { sarConfigSlice } from '@discngine/moosa-store/sarConfig';
import { sarTableDataSlice } from '@discngine/moosa-store/sarTableData';
import { scoresSlice } from '@discngine/moosa-store/scores';
import { scoringTemplateSlice } from '@discngine/moosa-store/scoringTemplate';
import { tableConfigSlice } from '@discngine/moosa-store/tableConfig';
import { tableDataSlice } from '@discngine/moosa-store/tableData';
import { tableInfoSlice } from '@discngine/moosa-store/tableInfo';
import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import reduxLogger from 'redux-logger';

import { dataService } from '../dataService';

import { chartsSlice } from './charts/charts.slice';
import { IChartsSubRoot } from './charts/IChartsState';
import { datasetSlice } from './datasetSlice';
import { ISarMatrixSubRoot } from './sarMatrix/ISarMatrixState';
import { sarMatrixSlice } from './sarMatrix/sarMatrix.slice';
import { ISarRadiantChartSubRoot } from './sarRadiantChart/ISarRadiantChartState';
import { sarRadiantChartSlice } from './sarRadiantChart/sarRadiantChart.slice';
import { ISarRadiantChartMultipleSubRoot } from './sarRadiantChartMultiple/ISarRadiantChartMultipleState';
import { sarRadiantChartMultipleSlice } from './sarRadiantChartMultiple/sarRadiantChartMultiple.slice';
import { ISarTableSubRoot } from './sarTable/ISarTableState';
import { sarTableSlice } from './sarTable/sarTable.slice';

decisionTreesWithVersionsSlice.configureDataProvider(
  getDecisionTreesDataProvider(dataService)
);

export type RootState = IChartsSubRoot &
  ISarRadiantChartSubRoot &
  ISarRadiantChartMultipleSubRoot &
  ISarTableSubRoot &
  ISarMatrixSubRoot & {
    [decisionTreeConfigSlice.name]: ReturnType<typeof decisionTreeConfigSlice.reducer>;
    [datasetSlice.name]: ReturnType<typeof datasetSlice.reducer>;
    [scoringTemplateSlice.name]: ReturnType<typeof scoringTemplateSlice.reducer>;
    [tableDataSlice.name]: ReturnType<typeof tableDataSlice.reducer>;
    [scoresSlice.name]: ReturnType<typeof scoresSlice.reducer>;
    [tableConfigSlice.name]: ReturnType<typeof tableConfigSlice.reducer>;
    [tableInfoSlice.name]: ReturnType<typeof tableInfoSlice.reducer>;
    [sarConfigSlice.name]: ReturnType<typeof sarConfigSlice.reducer>;
    [sarTableDataSlice.name]: ReturnType<typeof sarTableDataSlice.reducer>;
    [decisionTreesWithVersionsSlice.slice.name]: ReturnType<
      typeof decisionTreesWithVersionsSlice.slice.reducer
    >;
  };

const reducers = {
  chartsData: chartsSlice.reducer,
  sarRadiantChart: sarRadiantChartSlice.reducer,
  sarRadiantChartMultiple: sarRadiantChartMultipleSlice.reducer,
  sarTable: sarTableSlice.reducer,
  sarMatrix: sarMatrixSlice.reducer,
  [decisionTreeConfigSlice.name]: decisionTreeConfigSlice.reducer,
  [datasetSlice.name]: datasetSlice.reducer,
  [scoringTemplateSlice.name]: scoringTemplateSlice.reducer,
  [tableDataSlice.name]: tableDataSlice.reducer,
  [scoresSlice.name]: scoresSlice.reducer,
  [tableConfigSlice.name]: tableConfigSlice.reducer,
  [tableInfoSlice.name]: tableInfoSlice.reducer,
  [sarConfigSlice.name]: sarConfigSlice.reducer,
  [sarTableDataSlice.name]: sarTableDataSlice.reducer,
  [decisionTreesWithVersionsSlice.slice.name]:
    decisionTreesWithVersionsSlice.slice.reducer,
};

export const store = configureStore({
  reducer: combineReducers(reducers),
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    });

    if (process.env.NODE_ENV === 'development') {
      middleware.push(reduxLogger);
    }

    return middleware;
  },
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk<T = void> = ThunkAction<T, RootState, unknown, Action<string>>;
