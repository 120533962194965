import {
  ColumnId,
  FieldType,
  IColumnMetaInfo,
  PortGroupingType,
} from '@discngine/moosa-models';
import {
  selectDecisionTreeDefaultPortGrouping,
  selectDecisionTreeGroupColumnId,
  setDecisionTreeGroupColumnId,
  setDecisionTreeDefaultPortGrouping,
} from '@discngine/moosa-store/decisionTreeConfig';
import { selectTableColumnsMap } from '@discngine/moosa-store/tableInfo';
import { Select } from 'antd';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './DecisionTreeProperties.module.less';

export const DecisionTreeProperties = () => {
  const dispatch = useDispatch();

  const groupColumnId = useSelector(selectDecisionTreeGroupColumnId);
  const defaultPortGrouping = useSelector(selectDecisionTreeDefaultPortGrouping);

  const columnMeta: Record<ColumnId, IColumnMetaInfo> =
    useSelector(selectTableColumnsMap);

  const columns = useMemo(() => {
    const columns = [];

    for (const columnId in columnMeta) {
      const { type, isDiscreteColumn } = columnMeta[columnId];

      if (isDiscreteColumn && (type === FieldType.String || type === FieldType.Number)) {
        columns.push(columnId);
      }
    }

    return columns;
  }, [columnMeta]);

  const portGroupingTypes: { value: PortGroupingType; label: string }[] = [
    { value: PortGroupingType.Regular, label: 'No grouping' },
    { value: PortGroupingType.FalseMissing, label: 'False + Missing' },
    { value: PortGroupingType.TrueMissing, label: 'True + Missing' },
    { value: PortGroupingType.HideMissing, label: 'Hide missing values' },
  ];

  return (
    <>
      <div>
        <h3 className={styles.title}>Group Column</h3>

        <Select
          allowClear={true}
          placeholder="Column with groups"
          value={groupColumnId}
          onClear={() => dispatch(setDecisionTreeGroupColumnId(null))}
          onSelect={(value) => dispatch(setDecisionTreeGroupColumnId(value))}
        >
          {columns.map((column, index) => (
            <Select.Option key={index} value={column}>
              {column}
            </Select.Option>
          ))}
        </Select>
      </div>

      <div style={{ marginTop: '20px' }}>
        <h3 className={styles.title}>Default port grouping</h3>

        <Select
          allowClear={false}
          placeholder="Port grouping"
          value={defaultPortGrouping}
          onSelect={(value) => dispatch(setDecisionTreeDefaultPortGrouping(value))}
        >
          {portGroupingTypes.map(({ value, label }, index) => (
            <Select.Option key={index} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </>
  );
};
