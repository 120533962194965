import { IDatasetMetaModel } from '@discngine/moosa-models/src';
import { createListSlice } from '@discngine/moosa-store/dist/createListSlice';

import { dataService } from '../dataService';

export const {
  slice: datasetSlice,
  useUndeleteDataset,
  useUpdateDataset,
  useGetDataset,
  useDeleteDataset,
  useInfiniteListDataset,
  useCreateDataset,
  configureDataProvider,
} = createListSlice<
  'dataset',
  IDatasetMetaModel & { _id: string },
  Parameters<NonNullable<typeof dataService.getDatasetList>>[0]
>({ sliceName: 'dataset' });

configureDataProvider({
  getItem: dataService.getDatasetById,
  deleteItem: dataService.deleteDatasetById,
  undeleteItem: dataService.undeleteDatasetById,
  updateItem: dataService.updateDatasetById,
  getList: dataService.getDatasetList,
  createItem: dataService.createDataset,
});
