import { Alert, Typography } from 'antd';
import React, { Component, Fragment } from 'react';

import Main from './layout/Main';

import './App.less';

interface State {
  error: Error | null;
}

class App extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error: Error) {
    this.setState({ error });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="text-center">
          <Alert
            description={
              <div>
                Try refreshing the browser window, or report the error to the admin team.
                <div>
                  <i>Details of the error: </i> <br />
                  <Typography.Text copyable>
                    {(error.stack || error.message).split(/\n/g).map((errorLine) => (
                      <Fragment key={errorLine}>
                        <code>{errorLine}</code>
                        <br />
                      </Fragment>
                    )) || error.toString()}
                  </Typography.Text>
                </div>
              </div>
            }
            message="Something went wrong..."
            showIcon
            style={{ maxWidth: 500, margin: 'auto' }}
            type="error"
          />
        </div>
      );
    }

    return <Main />;
  }
}

export default App;
