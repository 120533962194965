import {
  DesirabilityFunctionType,
  FieldType,
  IDatasetMetaModel,
  IDesirabilityFunction,
  IScoringTemplate,
  MeasurementError,
  ILinePoint,
  ILineDiscretePoint,
  IScoringTemplateState,
  SortFunctionTableType,
} from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { IColumnMetaInfo } from '@discngine/moosa-store/tableInfo';

import {
  getDefaultScoringFunction,
  SCORING_FUNCTIONS,
  SCORING_FUNCTIONS_RULES,
} from './scoringFunctionService';
import { convertTemplateToTableDataType } from './scoringTemplate.service';

const initialState: IScoringTemplateState = {
  func: {},
  changed: false,
  order: [],
  openPropertyPanels: {},
  customFunctions: [],
};

export const scoringTemplateSlice = createSlice({
  name: 'scoringTemplate',
  initialState,
  reducers: {
    setScoringWeight(
      state,
      { payload: { col, weight } }: PayloadAction<{ col: string; weight: number }>
    ) {
      state.func[col].weight = weight;

      state.changed = true;
    },
    toggleColumnInScoring(
      state,
      action: PayloadAction<{ col: string; isInUse: boolean }>
    ) {
      state.func[action.payload.col].isInUse = action.payload.isInUse;
      state.changed = true;
    },
    changeDesirabilityFunction(
      state,
      action: PayloadAction<{
        column: string;
        name: string;
        metadata: IColumnMetaInfo;
      }>
    ) {
      const { column, name, metadata } = action.payload;

      const predefined = SCORING_FUNCTIONS.find((func) => func.name === name);

      let type, functionParams: any;

      if (predefined) {
        type = predefined.type;
        functionParams = SCORING_FUNCTIONS_RULES[type].init(metadata);
      } else {
        const custom = state.customFunctions.find((func) => func.name === name)!;

        type = custom.type;
        functionParams = SCORING_FUNCTIONS_RULES[type].fromServerTemplate(
          custom.points.map((point) => ({ ...point, id: uuid() })),
          metadata
        );
      }

      state.func[column].type = type;
      state.func[column].name = name;

      if (type === DesirabilityFunctionType.discrete) {
        state.func[column].isDiscreteStringFunction = metadata.type === FieldType.String;
      }
      state.func[column].functionParams[type] = functionParams;
      state.changed = true;
    },
    changeDesirabilityParams(
      state,
      action: PayloadAction<{
        column: string;
        type: DesirabilityFunctionType;
        params: any;
      }>
    ) {
      const { column, type, params } = action.payload;

      state.func[column].functionParams[type] = params;
      state.changed = true;
    },
    setFromSavedTemplate(
      state,
      action: PayloadAction<{
        template: IScoringTemplate;
        columnMap: { [id: string]: IColumnMetaInfo };
      }>
    ) {
      const { template, columnMap } = action.payload;

      const convertedTemplate = convertTemplateToTableDataType(template, columnMap);

      state.func = convertedTemplate!.func;
      state.order = convertedTemplate!.order;
      state.changed = false;
    },
    templateSaved(state) {
      state.changed = false;
    },
    changeColumnOrder(
      state,
      action: PayloadAction<{ fromColumn: string; toColumn: string }>
    ) {
      const { fromColumn, toColumn } = action.payload;
      const order = state.order;
      const fromIndex = order.indexOf(fromColumn);
      const toIndex = order.indexOf(toColumn);
      const item = order.splice(fromIndex, 1)[0];

      order.splice(toIndex, 0, item);
      state.changed = true;
    },
    addColumnToScoring(
      state,
      action: PayloadAction<{ colId: string; metadata: IColumnMetaInfo }>
    ) {
      const { colId, metadata } = action.payload;

      state.func[colId] = getDefaultScoringFunction(metadata);
      state.order.unshift(colId);
      state.openPropertyPanels[colId] = 1;
      state.changed = true;
    },
    removeColumnFromScoring(state, action: PayloadAction<string>) {
      const colId = action.payload;

      const index = state.order.indexOf(colId);

      state.order.splice(index, 1);
      delete state.func[colId];
      delete state.openPropertyPanels[colId];

      state.changed = true;
    },
    initFromTableMetadata(state, action: PayloadAction<IDatasetMetaModel>) {
      state.func = {};
      state.order = [];
      state.openPropertyPanels = {};
      state.changed = false;
    },
    resetToDefault(state) {
      state.func = {};
      state.order = [];
      state.openPropertyPanels = {};
      state.changed = false;
    },
    setColumnColor(state, { payload: { column, color } }) {
      state.func[column].color = color;
      state.changed = true;
    },
    toggleColumnPropertyOpen(state, { payload: { column, open } }) {
      if (open) {
        state.openPropertyPanels[column] = 1;
      } else {
        delete state.openPropertyPanels[column];
      }
    },
    movePoint(
      state,
      {
        payload: { column, type, point },
      }: PayloadAction<{
        column: string;
        type: DesirabilityFunctionType;
        point: ILinePoint;
      }>
    ) {
      const params = state.func[column].functionParams[type] as any;

      // @ts-ignore
      state.func[column].functionParams[type] = SCORING_FUNCTIONS_RULES[type].movePoint(
        params,
        point
      );

      state.changed = true;
    },
    addPoint(
      state,
      {
        payload: { column, type, point, fieldType, xSort, ySort },
      }: PayloadAction<{
        column: string;
        type: DesirabilityFunctionType;
        fieldType: FieldType;
        xSort: SortFunctionTableType;
        ySort: SortFunctionTableType;
        point: Omit<ILinePoint | ILineDiscretePoint, 'id'>;
      }>
    ) {
      const params = state.func[column].functionParams[type] as any;

      // @ts-ignore
      state.func[column].functionParams[type] = SCORING_FUNCTIONS_RULES[type].addPoint(
        params,
        { ...point, id: uuid() },
        fieldType,
        xSort,
        ySort
      );

      state.changed = true;
    },
    removePoint(
      state,
      {
        payload: { column, type, index },
      }: PayloadAction<{
        column: string;
        type: DesirabilityFunctionType;
        index: number;
      }>
    ) {
      const params = state.func[column].functionParams[type] as any;

      // @ts-ignore
      state.func[column].functionParams[type] = SCORING_FUNCTIONS_RULES[type].removePoint(
        params,
        index
      );

      state.changed = true;
    },
    changeDiscretePointsOrder(
      state,
      action: PayloadAction<{
        fromPoint: string | number;
        toPoint: string | number;
        columnName: string;
      }>
    ) {
      const { fromPoint, toPoint, columnName } = action.payload;
      const points =
        state.func[columnName].functionParams[DesirabilityFunctionType.discrete]!.points;
      const fromIndex = points.findIndex((point) => point.x === fromPoint);

      const toIndex = points.map((point) => point.x).indexOf(toPoint);
      const item = points.splice(fromIndex, 1)[0];

      points.splice(toIndex, 0, item);
      state.changed = true;
    },
    setMissingValue(
      state,
      action: PayloadAction<{
        colId: string;
        replaceWith?: number | null;
        replaceWithY?: number | null;
        replaceWithColumn?: string | undefined;
        isDiscreteStringFunction?: boolean;
      }>
    ) {
      const {
        colId,
        replaceWith,
        replaceWithY: y,
        replaceWithColumn,
        isDiscreteStringFunction,
      } = action.payload;
      const hasX =
        typeof replaceWith === 'number' ||
        (isDiscreteStringFunction && typeof replaceWithColumn !== 'string');
      const hasY = typeof y === 'number';
      const hasColumn = typeof replaceWithColumn === 'string';

      if (hasX && hasY) {
        throw Error('Both replaceWith and replaceWithY were set');
      }

      let replaceWithY = y;

      if (hasY) {
        if (y < 0) {
          replaceWithY = 0;
        } else if (y > 1) {
          replaceWithY = 1;
        }
      }

      if (hasX || hasY || hasColumn) {
        state.func[colId].missingValue = { replaceWith, replaceWithY, replaceWithColumn };
      } else {
        delete state.func[colId].missingValue;
      }
      state.changed = true;
    },
    setErrorMode(
      state,
      action: PayloadAction<{
        colId: string;
        measurementError: MeasurementError;
      }>
    ) {
      state.func[action.payload.colId].measurementError = action.payload.measurementError;
      state.changed = true;
    },
    setFilterFrom(state, action: PayloadAction<{ colId: string; from: number | null }>) {
      const { colId, from } = action.payload;

      let filter = state.func[colId].filter;

      if (!filter) {
        filter = {};
        state.func[colId].filter = filter;
      }

      if (typeof from === 'number') {
        filter.from = from;
      } else {
        delete filter.from;
      }

      state.changed = true;
    },
    setFilterTo(state, action: PayloadAction<{ colId: string; to: number | null }>) {
      const { colId, to } = action.payload;

      let filter = state.func[colId].filter;

      if (!filter) {
        filter = {};
        state.func[colId].filter = filter;
      }

      if (typeof to === 'number') {
        filter.to = to;
      } else {
        delete filter.to;
      }

      state.changed = true;
    },
    reassignColumn(
      state,
      action: PayloadAction<{
        oldColumn: string;
        newColumn: string;
        metadata: IColumnMetaInfo;
      }>
    ) {
      const { oldColumn, newColumn, metadata } = action.payload;
      const index = state.order.indexOf(oldColumn);

      state.func[newColumn] = getDefaultScoringFunction(metadata);

      delete state.func[oldColumn];
      state.order[index] = newColumn;

      if (state.openPropertyPanels[oldColumn]) {
        state.openPropertyPanels[newColumn] = 1;
        delete state.openPropertyPanels[oldColumn];
      }

      state.changed = true;
    },
    setCustomFunctions(
      state,
      action: PayloadAction<{ functions: IDesirabilityFunction[] }>
    ) {
      state.customFunctions = action.payload.functions;
    },
    setScoringTemplateState(
      state,
      action: PayloadAction<{ templateState: IScoringTemplateState }>
    ) {
      state.func = action.payload.templateState.func;
      state.changed = action.payload.templateState.changed;
      state.order = action.payload.templateState.order;
      state.openPropertyPanels = action.payload.templateState.openPropertyPanels;
      state.customFunctions = action.payload.templateState.customFunctions;
    },
  },
});

export const {
  setScoringWeight,
  toggleColumnInScoring,
  changeDesirabilityFunction,
  changeDesirabilityParams,
  setFromSavedTemplate,
  templateSaved,
  addColumnToScoring,
  removeColumnFromScoring,
  changeColumnOrder,
  initFromTableMetadata,
  resetToDefault,
  setColumnColor,
  toggleColumnPropertyOpen,
  movePoint,
  addPoint,
  removePoint,
  setMissingValue,
  setErrorMode,
  setFilterFrom,
  setFilterTo,
  reassignColumn,
  setCustomFunctions,
  changeDiscretePointsOrder,
  setScoringTemplateState,
} = scoringTemplateSlice.actions;
