import { DeploymentUnitOutlined, DownOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { classNames } from '@discngine/moosa-common';
import { IDatasetMetaModel, SortField } from '@discngine/moosa-models';
import { Dropdown, Input, Menu, Typography, Skeleton, Empty, Button, Space } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Spinner from '../../shared/Spinner/Spinner';
import {
  useDeleteDataset,
  useGetDataset,
  useInfiniteListDataset,
  useUndeleteDataset,
} from '../../store/datasetSlice';

import DatasetList from './DatasetList/DatasetList';
import DatasetTable from './DatasetList/DatasetTable/DatasetTable';
import styles from './DatasetSelector.module.less';
import DatasetUpload from './DatasetUpload/DatasetUpload';

const { Title } = Typography;

enum SortType {
  TIME_A = 'TIME_A',
  TIME_D = 'TIME_D',
  ALPH_A = 'ALPH_A',
  ALPH_D = 'ALPH_D',
  PROF_A = 'PROF_A',
  PROF_D = 'PROF_D',
}

const SORT_TYPES: Record<
  SortType,
  {
    key: SortType;
    title: string;
    value: -1 | 1;
    field: keyof SortField<IDatasetMetaModel>;
  }
> = {
  [SortType.TIME_A]: {
    key: SortType.TIME_A,
    title: 'Recent',
    field: '$sort[updatedAt]',
    value: -1,
  },
  [SortType.TIME_D]: {
    key: SortType.TIME_D,
    title: 'Oldest',
    field: '$sort[updatedAt]',
    value: 1,
  },
  [SortType.ALPH_A]: {
    key: SortType.ALPH_A,
    title: 'A to Z',
    field: '$sort[fileName]',
    value: 1,
  },
  [SortType.ALPH_D]: {
    key: SortType.ALPH_D,
    title: 'Z to A',
    field: '$sort[fileName]',
    value: -1,
  },
  [SortType.PROF_A]: {
    key: SortType.PROF_A,
    title: 'With associated template',
    field: '$sort[scoringTemplateId]',
    value: -1,
  },
  [SortType.PROF_D]: {
    key: SortType.PROF_D,
    title: 'Without associated template',
    field: '$sort[scoringTemplateId]',
    value: 1,
  },
};

const LIMIT = 20;

const DatasetSelector: React.FC = () => {
  const history = useHistory();

  const [sortType, setSortType] = useState(SortType.TIME_A);

  const [searchQuery, setSearchQuery] = useState('');

  const currentSortType = useMemo(() => SORT_TYPES[sortType], [sortType]);

  const params = useMemo<Parameters<typeof useInfiniteListDataset>[0]>(
    () => ({
      limit: LIMIT,
      [currentSortType.field]: currentSortType.value,
      'fileName[$regex]': searchQuery ? searchQuery : null,
    }),
    [currentSortType.field, currentSortType.value, searchQuery]
  );

  const query = useInfiniteListDataset(params);

  const [nameFilter, setNameFilter] = useState('');
  const [uploadedItemId, setUploadedItemId] = useState<string | null>(null);

  const uploadErrorHandle = useCallback((file) => {
    console.error('upload failed', file);
  }, []);

  const uploadSuccessHandler = useCallback(
    (id: string) => {
      setUploadedItemId(id);
      query.revalidate().catch((err) => {
        console.error('revalidate failed', err);
      });
    },
    [query]
  );

  const onSearchChangedDebounced = useMemo(
    () => debounce(setSearchQuery, 1000),
    [setSearchQuery]
  );

  const { onDelete } = useDeleteDataset();
  const { onUndelete } = useUndeleteDataset();

  const { item, isLoading: isItemLoading } = useGetDataset({ id: uploadedItemId });

  const noDatasets = query.data.length === 0;
  const isInitialLoading = noDatasets && query.isLoading;

  const headerTitle = noDatasets
    ? 'Import a dataset for scoring'
    : 'Import/Open a dataset for scoring';

  const openDataset = useCallback((id) => {
    window.location.hash = `/data/${id}`;
  }, []);

  return (
    <div className={styles.content}>
      <PageHeader
        className={styles.pageHeader}
        extra={
          <Space size={8}>
            <Button
              icon={<DeploymentUnitOutlined />}
              type="link"
              onClick={() => history.push('/moosa-3d')}
            >
              Moosa 3D
            </Button>
          </Space>
        }
        title={isInitialLoading ? <Skeleton.Input active size="small" /> : headerTitle}
      />

      <div className={styles.main}>
        <header className={styles.header}>
          <Title className={styles.title} level={3}>
            Upload new file
          </Title>
        </header>

        <div className={styles.upload}>
          <DatasetUpload
            onUploadError={uploadErrorHandle}
            onUploadSuccess={uploadSuccessHandler}
          />
        </div>

        {uploadedItemId && (
          <>
            <div className={styles.tableHeader}>
              <Typography.Title className={styles.tableTitle} level={4}>
                Last file
              </Typography.Title>
            </div>

            <DatasetTable
              className={styles.tableBlockNoShrink}
              data={item ? [{ ...item, isUploaded: true }] : []}
              loading={isItemLoading}
              openDataset={openDataset}
              onRowDelete={(id) => {
                setUploadedItemId(null);

                return onDelete(id);
              }}
            />

            {!isItemLoading && !item && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </>
        )}

        <div className={styles.tableHeader}>
          <Title className={styles.tableTitle} level={4}>
            All files
          </Title>
          <div className={styles.spacer} />
          <Input.Search
            className={styles.searchInput}
            placeholder="Search dataset"
            value={nameFilter}
            onChange={(event) => {
              setNameFilter(event.target.value);
              onSearchChangedDebounced(event.target.value);
            }}
          />

          <div className={styles.sortDropdownWrapper}>
            <span>Sort by:</span>
            <Dropdown
              className={styles.sortDropdown}
              disabled={query.isLoading}
              overlay={
                <Menu
                  className={styles.sortDropdownMenu}
                  onClick={({ key }) => setSortType(key as SortType)}
                >
                  {Object.values(SORT_TYPES).map((element) => (
                    <Menu.Item key={element.key}>{element.title}</Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottomRight"
            >
              <button
                className={classNames('ant-dropdown-link', styles.sortDropdownTitle)}
                onClick={(event) => event.preventDefault()}
              >
                {currentSortType.title}
                <DownOutlined className={styles.sortDropdownIcon} />
              </button>
            </Dropdown>
          </div>
        </div>

        <div className={styles.dataRoot}>
          {/*show only on initial loading*/}
          {isInitialLoading && <Spinner />}

          {!query.isLoading && noDatasets && !uploadedItemId && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          {!noDatasets && (
            <DatasetList
              query={query}
              uploadedItem={uploadedItemId}
              onClick={openDataset}
              onDelete={onDelete}
              onUndelete={onUndelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DatasetSelector;
