import {
  FieldType,
  SpecialColumn,
  IDatasetMetaField,
  CellBackgroundMode,
  ITableConfigState,
  RowSizes,
  DEFAULT_COLUMN_NAMES,
  MISSING_VALUES_COLUMN_ID,
  RADAR_COLUMN_ID,
  ROW_NUMBER_COLUMN_ID,
  SCORE_COLUMN_ID,
  COMPARISON_SCORE_COLUMN_ID,
  ColumnId,
  ColumnType,
} from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InitTableInfoPayload, tableInfoSlice } from '@discngine/moosa-store/tableInfo';

import { PINNED_COLUMNS } from './tableConfig.selectors';

const initialState: ITableConfigState = {
  columnWidths: {},
  columnOrder: {},
  columnNames: {},
  columnVisibility: {},
  columnTypes: {},
  columnIsDiscreteFlags: {},
  columnIsDiscreteStringFlags: {},
  rowsSize: {},
  cellBackgroundMode: {},
  useTemplateColumns: false,
  isComputedColAdding: false,
  computedColEditing: null,
  isComparisonTemplateSelectorVisible: false,
};

const INITIAL_SORT_ORDER: { [key: string]: number } = {
  [FieldType.Structure]: 3,
  [FieldType.ID]: 2,
  [FieldType.Computed]: 1,
};

export const tableConfigSlice = createSlice({
  name: 'tableConfig',
  initialState,
  reducers: {
    setColumnWidth(state, { payload: { tableId, column, width } }) {
      let widths = state.columnWidths[tableId];

      widths = { ...widths };
      widths[column] = width;
      state.columnWidths[tableId] = widths;
    },
    setRowsSize(state, action: PayloadAction<{ tableId: string; size: RowSizes }>) {
      const { size, tableId } = action.payload;

      state.rowsSize[tableId] = size;
    },
    changeColumnOrder(state, { payload: { tableId, fromColumn, toColumn } }) {
      const order = [...state.columnOrder[tableId]];
      const fromIndex = order.indexOf(fromColumn);
      const toIndex = order.indexOf(toColumn);
      const item = order.splice(fromIndex, 1)[0];

      order.splice(toIndex, 0, item);
      state.columnOrder[tableId] = order;
      state.useTemplateColumns = false;
    },
    setColumnVisibility(state, { payload: { tableId, column, visible, type } }) {
      const visibility = { ...state.columnVisibility[tableId] };

      visibility[column] = visible;
      state.columnVisibility[tableId] = visibility;

      const colType = state.columnTypes[tableId][column];

      if (!PINNED_COLUMNS.includes(colType)) {
        state.useTemplateColumns = false;
      }

      // TODO: remove this piece if decided it is not needed
      // column reordering on visibility change removed for now

      // if (!visible) {
      //   return;
      // }
      //
      // // put the field being made visible up after the last enabled
      // const order = [...state.columnOrder[tableId]];
      //
      // let lastVisibleIndex = -1;
      //
      // for (let i = order.length - 1; i >= 0; --i) {
      //   const col = order[i];
      //
      //   if (col !== column && visibility[col]) {
      //     lastVisibleIndex = i;
      //     break;
      //   }
      // }
      //
      // if (lastVisibleIndex < order.length - 1) {
      //   const fromIndex = order.indexOf(column);
      //   const toIndex = lastVisibleIndex + 1;
      //   const item = order.splice(fromIndex, 1)[0];
      //
      //   order.splice(toIndex, 0, item);
      //   state.columnOrder[tableId] = order;
      // }
    },
    setCellBackgroundMode(
      state,
      action: PayloadAction<{ tableId: string; mode: CellBackgroundMode }>
    ) {
      const { tableId, mode } = action.payload;

      state.cellBackgroundMode[tableId] = mode;
    },
    setUseTemplateColumns(state, action: PayloadAction<boolean>) {
      state.useTemplateColumns = action.payload;
    },
    overwriteColumnOrderAndVisibility(
      state,
      action: PayloadAction<{
        tableId: string;
        order: string[];
        visibility: Record<ColumnId, boolean>;
      }>
    ) {
      const { order, tableId, visibility } = action.payload;

      state.columnOrder[tableId] = order;
      state.columnVisibility[tableId] = visibility;
    },
    addComputedColumn(state) {
      state.isComputedColAdding = true;
    },
    cancelComputedColumn(state) {
      state.isComputedColAdding = false;
      state.computedColEditing = null;
    },
    editComputedColumn(state, action: PayloadAction<{ columnId: string }>) {
      state.computedColEditing = action.payload.columnId;
    },
    showComparisonTemplateSelector(state) {
      state.isComparisonTemplateSelectorVisible = true;
    },
    hideComparisonTemplateSelector(state) {
      state.isComparisonTemplateSelectorVisible = false;
    },
    setColumnNames(
      state,
      action: PayloadAction<{
        tableId: string;
        columnNames: Record<ColumnId, any>;
      }>
    ) {
      state.columnNames[action.payload.tableId] = action.payload.columnNames;
    },
  },
  extraReducers: {
    [tableInfoSlice.actions.initTableInfo.type]: (
      state,
      action: PayloadAction<InitTableInfoPayload>
    ) => {
      const {
        data: { _id: tableId, fields },
        computedColumnsChanges,
      } = action.payload;
      const sortedFields = [...fields].sort(
        (f1, f2) =>
          (INITIAL_SORT_ORDER[f2.type] || 0) - (INITIAL_SORT_ORDER[f1.type] || 0)
      );
      const columns: string[] = sortedFields.map((field) => field.name);

      if (computedColumnsChanges) {
        const { colUpdated, colAdded, colDeleted } = computedColumnsChanges;

        const columnWidths = state.columnWidths[tableId];
        const columnOrder = state.columnOrder[tableId];
        const columnVisibility = state.columnVisibility[tableId];
        const columnNames = state.columnNames[tableId];
        const columnTypes = state.columnTypes[tableId];
        const columnIsDiscreteFlags = state.columnIsDiscreteFlags[tableId];

        if (colUpdated) {
          const { from, to } = colUpdated;

          const colUpdatedField = fields.find(
            (field: IDatasetMetaField) => field.name === to
          );

          delete columnWidths[from];
          delete columnVisibility[from];
          delete columnNames[from];
          delete columnTypes[from];
          delete columnIsDiscreteFlags[from];

          columnWidths[to] = 200;
          columnVisibility[to] = true;
          columnNames[to] = to;
          columnTypes[to] = FieldType.Computed;
          columnOrder[columnOrder.indexOf(from)] = to;
          columnIsDiscreteFlags[to] =
            colUpdatedField!.statistics!.textCategories !== null;
        }

        if (colAdded) {
          const colAddedField = fields.find(
            (field: IDatasetMetaField) => field.name === colAdded
          );

          columnWidths[colAdded] = 200;
          columnOrder.splice(5, 0, colAdded);
          columnVisibility[colAdded] = true;
          columnNames[colAdded] = colAdded;
          columnTypes[colAdded] = FieldType.Computed;
          columnIsDiscreteFlags[colAdded] =
            colAddedField!.statistics!.textCategories !== null;
          state.useTemplateColumns = false;
        }

        if (colDeleted) {
          delete columnWidths[colDeleted];
          state.columnOrder[tableId] = columnOrder.filter((col) => col !== colDeleted);
          delete columnVisibility[colDeleted];
          delete columnNames[colDeleted];
          delete columnTypes[colDeleted];
        }

        return;
      }

      const columnTypes = sortedFields.reduce<{ [key: string]: ColumnType }>(
        (acc, field) => {
          acc[field.name] = field.type;

          return acc;
        },
        {}
      );

      const columnIsDiscreteFlags = sortedFields.reduce<{ [key: string]: boolean }>(
        (acc, field) => {
          acc[field.name] = field.statistics?.textCategories !== null;

          return acc;
        },
        {}
      );

      const columnIsDiscreteStringFlags = sortedFields.reduce<{ [key: string]: boolean }>(
        (acc, field) => {
          acc[field.name] =
            field.statistics?.textCategories !== null && field.type === FieldType.String;

          return acc;
        },
        {}
      );

      columns.unshift(RADAR_COLUMN_ID);
      columnTypes[RADAR_COLUMN_ID] = SpecialColumn.Radar;

      columns.unshift(MISSING_VALUES_COLUMN_ID);
      columnTypes[MISSING_VALUES_COLUMN_ID] = SpecialColumn.Missing;

      columns.unshift(COMPARISON_SCORE_COLUMN_ID);
      columnTypes[COMPARISON_SCORE_COLUMN_ID] = SpecialColumn.Comparison;

      columns.unshift(SCORE_COLUMN_ID);
      columnTypes[SCORE_COLUMN_ID] = SpecialColumn.Score;

      //needed for column DND
      columnTypes[ROW_NUMBER_COLUMN_ID] = SpecialColumn.RowNumber;

      const widths: Record<string, number> = {};
      const visibility: Record<string, boolean> = {};
      const names: Record<string, string> = {};

      columns.forEach((col) => {
        widths[col] = 200;
        visibility[col] = col !== MISSING_VALUES_COLUMN_ID;
        names[col] = DEFAULT_COLUMN_NAMES[col] || col;
      });

      state.columnWidths[tableId] = widths;
      state.columnOrder[tableId] = columns;
      state.columnVisibility[tableId] = visibility;
      state.columnNames[tableId] = names;
      state.columnTypes[tableId] = columnTypes;
      state.columnIsDiscreteFlags[tableId] = columnIsDiscreteFlags;
      state.columnIsDiscreteStringFlags[tableId] = columnIsDiscreteStringFlags;
      state.cellBackgroundMode[tableId] = CellBackgroundMode.None;
      state.useTemplateColumns = false;
    },
  },
});

export const {
  setColumnWidth,
  setRowsSize,
  changeColumnOrder,
  setColumnVisibility,
  setUseTemplateColumns,
  setCellBackgroundMode,
  addComputedColumn,
  cancelComputedColumn,
  editComputedColumn,
  overwriteColumnOrderAndVisibility,
  showComparisonTemplateSelector,
  hideComparisonTemplateSelector,
  setColumnNames,
} = tableConfigSlice.actions;
