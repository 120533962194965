import { Formula } from './IFormula';

export enum FieldType {
  Number = 'Number',
  String = 'String',
  Structure = 'Structure', // add server fix
  ID = 'ID',
  Computed = 'Computed',
}

export interface ITextCategories {
  value: number | string;
  count: number;
}

export interface IDatasetMetaStatistics {
  min: number;
  max: number;
  missingValues: number;
  histogramStep: number;
  histogram: number[];
  textCategories: ITextCategories[];
}

export interface IDatasetMetaField {
  name: string;
  type: FieldType;
  statistics?: IDatasetMetaStatistics; // available only for FieldType.Number or Computed
  formula?: Formula;
  isDiscreteColumn: boolean;
}

export interface IDatasetMetaModel {
  _id: any;
  fields: IDatasetMetaField[];
  rowCount: number;
  fileName: string;
  fileSize: number;
  missingValues: number;
  updatedAt: Date;
  createdAt: Date;
  scoringTemplateId: string;
  scoringTemplateName?: string;
  isUploaded?: boolean; // UI field
}
