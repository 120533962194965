import {
  IAppInfo,
  ICollectionResponse,
  IDatasetMetaModel,
  IDatasetRowWithScore,
  IDesirabilityFunction,
  IScoringCalculationRequest,
  IScoringTemplate,
  IScoringTemplateRequest,
  ISmartChartCreateResponse,
  ISmartChartModel,
  ISmartChartModelParams,
  IDecisionTree,
  IMoosaDataService,
  DecisionTreeId,
  DefaultParams,
  SortField,
  ResponseErrors,
  FilterField,
} from '@discngine/moosa-models';
import { stringify } from 'qs';

/**
 * Middleware function to handle errors in the fetch response.
 *
 * @param response - The fetch response to handle.
 * @throws - Throws an error with a specific message based on the response status.
 * @returns - Returns the original response if it is okay.
 */
const errorHandlerMiddleware = (response: Response) => {
  if (!response.ok) {
    switch (response.status) {
      case 404:
        throw new Error(ResponseErrors.NOT_FOUND);
      default:
        throw new Error(ResponseErrors.UNKNOWN);
    }
  }

  return response;
};

/**
 * Wrapper function for the fetch API to handle errors and parse JSON response.
 *
 * @param input - The input URL or the request object to fetch.
 * @param init - An optional options object to configure the fetch request.
 * @returns - Returns a promise that resolves to the parsed JSON data from the fetch response.
 */
const protectedFetch = (input: RequestInfo | URL, init?: RequestInit | undefined) => {
  return fetch(input, init)
    .then(errorHandlerMiddleware)
    .then((response) => response.json());
};

export class MoosaDataService implements IMoosaDataService {
  constructor(private serverPath: string) {}

  /*** Dataset List ***/
  getDatasetList = (
    params: DefaultParams &
      SortField<IDatasetMetaModel> &
      FilterField<IDatasetMetaModel> &
      Partial<IDatasetMetaModel> = { limit: 200, skip: 0 }
  ): Promise<ICollectionResponse<IDatasetMetaModel>> => {
    const { limit, skip, ...rest } = params;

    return protectedFetch(
      `${this.serverPath}/datasets${stringify(
        { ...rest, $limit: limit, $skip: skip },
        { addQueryPrefix: true, skipNulls: true }
      )}`
    );
  };

  createDataset = (
    params?: Omit<IDatasetMetaModel, '_id'>
  ): Promise<IDatasetMetaModel> => {
    return protectedFetch(`${this.serverPath}/datasets`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(params),
    });
  };

  getDatasetById = (id: string): Promise<IDatasetMetaModel> => {
    return protectedFetch(`${this.serverPath}/datasets/${id}`);
  };

  updateDatasetById = (
    params: Partial<IDatasetMetaModel> & { _id: string }
  ): Promise<IDatasetMetaModel> => {
    return protectedFetch(`${this.serverPath}/datasets/${params._id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'PATCH',
      body: JSON.stringify(params),
    });
  };

  deleteDatasetById = (
    datasetId: string
  ): Promise<IDatasetMetaModel & { deletedAt: string }> =>
    protectedFetch(`${this.serverPath}/datasets/${datasetId}`, { method: 'DELETE' });

  undeleteDatasetById = (datasetId: string): Promise<IDatasetMetaModel> =>
    protectedFetch(`${this.serverPath}/datasets/${datasetId}/undelete`, {
      method: 'POST',
    });

  /*** Dataset Table ***/

  getDatasetMetadata = (id: string): Promise<IDatasetMetaModel> =>
    protectedFetch(`${this.serverPath}/datasets/${id}`);

  getDataWithScore = (
    tableId: string,
    payload: IScoringCalculationRequest
  ): Promise<ICollectionResponse<IDatasetRowWithScore>> =>
    protectedFetch(`${this.serverPath}/datasets/${tableId}/scoring`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  assignScoringTemplate = (tableId: string, scoringTemplateId: string | null): any =>
    protectedFetch(`${this.serverPath}/datasets/${tableId}`, {
      method: 'PATCH',
      body: JSON.stringify({ scoringTemplateId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  patchComputedColumns = (tableId: string, computed: any[]): any =>
    protectedFetch(`${this.serverPath}/datasets/${tableId}`, {
      method: 'PATCH',
      body: JSON.stringify({ computed }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  /*** Charts ***/

  smartChartCreate = (
    datasetId: string,
    payload: ISmartChartModelParams
  ): Promise<ISmartChartCreateResponse> =>
    protectedFetch(`${this.serverPath}/datasets/${datasetId}/smart-chart`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  getSmartChart = (datasetId: string): Promise<ISmartChartModel> =>
    protectedFetch(`${this.serverPath}/datasets/${datasetId}/smart-chart`).then(
      (data) => {
        const smartChart = data[0];

        if (smartChart?.error) {
          throw new Error(smartChart.error);
        }

        return smartChart;
      }
    );

  /*** Scoring Template ***/
  getScoringTemplate = (templateId: string): Promise<IScoringTemplate> =>
    protectedFetch(`${this.serverPath}/scoring-templates/${templateId}`);

  getScoringTemplates = (params: {
    skip?: number;
    limit?: number;
    name?: string;
  }): Promise<ICollectionResponse<IScoringTemplate>> => {
    const { limit, skip, name, ...rest } = params ?? {};

    return protectedFetch(
      `${this.serverPath}/scoring-templates${stringify(
        { ...rest, $limit: limit, $skip: skip, 'name[$regex]': name },
        { addQueryPrefix: true, skipNulls: true }
      )}`
    );
  };

  getScoringTemplateVersions = (
    parentId: string
  ): Promise<ICollectionResponse<IScoringTemplate>> =>
    protectedFetch(`${this.serverPath}/scoring-templates?parentId=${parentId}`);

  deleteScoringTemplate = (templateId: string): any =>
    protectedFetch(`${this.serverPath}/scoring-templates/${templateId}`, {
      method: 'DELETE',
    });

  undeleteScoringTemplate = (templateId: string): any =>
    protectedFetch(`${this.serverPath}/scoring-templates/${templateId}/undelete`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  checkScoringTemplateName = (name: string): Promise<{ success: boolean }> =>
    protectedFetch(`${this.serverPath}/scoring-templates/validate-name`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'POST',
      body: JSON.stringify({ name }),
    });

  /* desirability functions*/

  createDesirabilityFunction = (payload: Omit<IDesirabilityFunction, '_id'>): any =>
    protectedFetch(`${this.serverPath}/desirability-functions`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  getDesirabilityFunctionById = (id: string): Promise<IDesirabilityFunction> =>
    protectedFetch(`${this.serverPath}/desirability-functions/${id}`);

  updateDesirabilityFunctionById = (
    params: IDesirabilityFunction
  ): Promise<IDesirabilityFunction> => {
    return protectedFetch(`${this.serverPath}/desirability-functions/${params._id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'PATCH',
      body: JSON.stringify(params),
    });
  };

  deleteDesirabilityFunctionById = (
    id: string
  ): Promise<IDesirabilityFunction & { deletedAt: string }> => {
    return protectedFetch(`${this.serverPath}/desirability-functions/${id}`, {
      method: 'DELETE',
    });
  };

  undeleteDesirabilityFunctionById = (id: string): Promise<IDesirabilityFunction> => {
    return protectedFetch(`${this.serverPath}/desirability-functions/${id}/undelete`, {
      method: 'POST',
    });
  };

  getDesirabilityFunctionList = (
    params: DefaultParams &
      SortField<IDesirabilityFunction> &
      FilterField<IDesirabilityFunction> &
      Partial<IDesirabilityFunction> = { limit: 200, skip: 0 }
  ): Promise<ICollectionResponse<IDesirabilityFunction>> => {
    const { limit, skip, ...rest } = params;

    return protectedFetch(
      `${this.serverPath}/desirability-functions${stringify(
        { ...rest, $limit: limit, $skip: skip },
        { addQueryPrefix: true, skipNulls: true }
      )}`
    );
  };

  checkDesirabilityFunctionName = (payload: {
    name: string;
    _id?: string;
  }): Promise<{ success: boolean }> =>
    protectedFetch(`${this.serverPath}/desirability-functions/validate-name`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });

  createScoringTemplate = (payload: IScoringTemplateRequest): Promise<IScoringTemplate> =>
    protectedFetch(`${this.serverPath}/scoring-templates`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(payload),
    });

  updateScoringTemplate = (
    templateId: string,
    payload: IScoringTemplateRequest
  ): Promise<IScoringTemplate> =>
    protectedFetch(`${this.serverPath}/scoring-templates/${templateId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'PATCH',
      body: JSON.stringify(payload),
    });

  /*** Decision tree ***/
  getDecisionTrees = (params: {
    parentId?: DecisionTreeId | 'all';
    skip?: number;
    limit?: number;
    name?: string;
  }): Promise<ICollectionResponse<IDecisionTree>> => {
    const { limit, skip, name, ...rest } = params;

    return protectedFetch(
      `${this.serverPath}/decision-trees${stringify(
        { ...rest, $limit: limit, $skip: skip, 'name[$regex]': name },
        {
          addQueryPrefix: true,
          skipNulls: true,
        }
      )}`
    );
  };

  checkDecisionTreeName = (payload: {
    name: string;
    _id?: string;
    versionName?: string;
    parentId?: string;
  }): Promise<{ success: boolean }> =>
    protectedFetch(`${this.serverPath}/decision-trees/validate-name`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'POST',
      body: JSON.stringify(payload),
    });

  createDecisionTree = (payload: IDecisionTree): Promise<IDecisionTree> =>
    protectedFetch(`${this.serverPath}/decision-trees`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  getDecisionTree = (decisionTreeId: DecisionTreeId): Promise<IDecisionTree> =>
    protectedFetch(`${this.serverPath}/decision-trees/${decisionTreeId}`);

  updateDecisionTree = (
    decisionTreeId: DecisionTreeId,
    payload: IDecisionTree
  ): Promise<IDecisionTree> =>
    protectedFetch(`${this.serverPath}/decision-trees/${decisionTreeId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: 'PATCH',
      body: JSON.stringify(payload),
    });

  deleteDecisionTree = (decisionTreeId: DecisionTreeId): any =>
    protectedFetch(`${this.serverPath}/decision-trees/${decisionTreeId}`, {
      method: 'DELETE',
    });

  undeleteDecisionTree = (decisionTreeId: DecisionTreeId): any =>
    protectedFetch(`${this.serverPath}/decision-trees/${decisionTreeId}/undelete`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });

  /*** Appinfo ***/
  getInfoAboutApplication = (): Promise<IAppInfo[]> =>
    protectedFetch(`${this.serverPath}/about`);
}
