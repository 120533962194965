import {
  IColumnMetaInfo,
  DesirabilityFunctionRules,
  ILinePoint,
  IRange,
  LogarithmicFunctionParams,
  ServerLinePoint,
} from '@discngine/moosa-models';

import { checkXBoundaries, extendLineToRange, segmentation } from './rulesUtils';

/**
 * derivative for
 * Math.log( ((base - 1) * (xVal - params.xForY0)) / (params.xForY1 - params.xForY0) + 1 ) / Math.log(base)
 */
function logDerivative(val: number, params: LogarithmicFunctionParams): number {
  const { base, xForY0, xForY1 } = params;
  const _a = (base - 1) / (xForY1 - xForY0);
  const _b = xForY0;
  const _c = Math.log(base);

  return _a / (_a * _c * (val - _b) + _c);
}

export const logarithmicFunctionRules: DesirabilityFunctionRules<LogarithmicFunctionParams> =
  {
    init(metadata: IColumnMetaInfo): LogarithmicFunctionParams {
      const params: LogarithmicFunctionParams = {
        base: 2.718,
        xForY0: metadata.statistics!.min,
        xForY1:
          metadata.statistics!.min !== metadata.statistics!.max
            ? metadata.statistics!.max
            : metadata.statistics!.min + 1,
      };

      return params;
    },
    getValue(xVal: number, params: LogarithmicFunctionParams): number {
      let minX, maxX, minY, maxY;

      if (params.xForY0 < params.xForY1) {
        minX = params.xForY0;
        minY = 0;
        maxX = params.xForY1;
        maxY = 1;
      } else {
        minX = params.xForY1;
        minY = 1;
        maxX = params.xForY0;
        maxY = 0;
      }

      if (xVal <= minX) {
        return minY;
      }

      if (xVal >= maxX) {
        return maxY;
      }

      const base = params.base === 1 ? 1.001 : params.base;

      return (
        Math.log(
          ((base - 1) * (xVal - params.xForY0)) / (params.xForY1 - params.xForY0) + 1
        ) / Math.log(base)
      );
    },
    getRange(params: LogarithmicFunctionParams, metadata: IColumnMetaInfo): IRange {
      const { min, max } = metadata.statistics!;

      return {
        min: Math.min(min, params.xForY0, params.xForY1),
        max: Math.max(max, params.xForY0, params.xForY1),
      };
    },
    toLine(params: LogarithmicFunctionParams, range: IRange): ILinePoint[] {
      const p0: ILinePoint = {
        id: 'first',
        x: params.xForY0,
        y: 0,
        originalPointIndex: 0,
        mark: true,
      };
      const p1: ILinePoint = {
        id: 'second',
        x: params.xForY1,
        y: 1,
        originalPointIndex: 1,
        mark: true,
      };
      const initialPoints = [p0, p1];

      const fx = (x: number) => logarithmicFunctionRules.getValue(x, params);
      const dfx = (x: number) => logDerivative(x, params);
      const points = segmentation(initialPoints, fx, dfx);

      // console.info('logarithm segmentation', points.length);

      return extendLineToRange(points, range);
    },
    toServerTemplate(params: LogarithmicFunctionParams): ServerLinePoint[] {
      // TODO - a crutch: fix the crutch
      return [
        {
          x: params.xForY0,
          y: params.base,
        },
        {
          x: params.xForY1,
          y: 0,
        },
      ];
    },
    fromServerTemplate(points: ServerLinePoint[]): LogarithmicFunctionParams {
      // TODO - and fix this as well
      return {
        base: points[0].y,
        xForY0: points[0].x,
        xForY1: points[1].x,
      };
    },

    movePoint(
      logParams: LogarithmicFunctionParams,
      { originalPointIndex, x: newX }: ILinePoint
    ) {
      const params = { ...logParams };

      const x = checkXBoundaries(newX);

      if (originalPointIndex === 0) {
        params.xForY0 = x;
      } else if (originalPointIndex === 1) {
        params.xForY1 = x;
      }

      return params;
    },
  };
