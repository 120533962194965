import { ColumnId, ColumnType } from './datasetCommon';

export type TableId = string;

export type TableColumnProperty<T> = Record<TableId, Record<ColumnId, T>>;

export enum CellBackgroundMode {
  None = 'None',
  Histogram = 'Histogram',
  Heatmap = 'Heatmap',
}

export enum RowSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export interface ITableConfigState {
  columnWidths: TableColumnProperty<number>;
  columnOrder: Record<TableId, ColumnId[]>;
  columnNames: TableColumnProperty<string>;
  columnVisibility: TableColumnProperty<boolean>;
  columnTypes: TableColumnProperty<ColumnType>;
  rowsSize: Record<TableId, RowSizes>;
  cellBackgroundMode: Record<TableId, CellBackgroundMode>;
  useTemplateColumns: boolean;
  isComputedColAdding: boolean;
  computedColEditing: string | null;
  columnIsDiscreteFlags: TableColumnProperty<boolean>;
  columnIsDiscreteStringFlags: TableColumnProperty<boolean>;
  isComparisonTemplateSelectorVisible: boolean;
}

export interface ITableConfigSubRoot {
  tableConfig: ITableConfigState;
}

export type IColumnLabelMap = Record<ColumnId, { label: string }>;
