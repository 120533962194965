import { StructureRendererCore } from '@discngine/moosa-models';
import tinycolor from 'tinycolor2';

import { IRDKitContext } from './RDKitContext';

export const ERROR_WAITING_FOR_RDKIT = 'Waiting for RDKit to load';

/** you must free memory allocated by this function, use delete()  */
export const smilesToMolecule = (
  RDKit: Record<string, any>,
  smiles: string, // todo molstr: Smiles | Molfile
  toCTAB: boolean = false
) => {
  const mol = RDKit.get_mol(smiles);

  if (toCTAB) {
    const mol2 = RDKit.get_mol(mol.get_molblock());

    mol.delete();

    return mol2;
  }

  return mol;
};

interface IMatch {
  atoms: number[];
  bonds: number[];
}

const colors = ['#F18A8A', '#FFA940', '#73D13D', '#597EF7', '#F759AB', '#FFEC3D'];
const defaultHighlightColor = 'ff7f7f';

const colorSvg = (
  svg: SVGSVGElement,
  coreSmilesArray: StructureRendererCore[],
  matches: Record<number, IMatch>
) => {
  coreSmilesArray.forEach((structure, index) => {
    if (matches[index]) {
      let color = colors[index % colors.length];

      if (typeof structure !== 'string') {
        color = structure.color;
      }

      matches[index].atoms.forEach((atom) => {
        const circle = svg.querySelector('ellipse.atom-' + atom) as HTMLElement;

        circle.style.fill = color;
        circle.style.stroke = color;

        if (color === 'transparent') {
          const atoms = svg.querySelectorAll('path.atom-' + atom);

          for (const atom of atoms) {
            // Text has the only classname in class list
            const eletmentIsAtomName = (atom as HTMLElement).classList.length === 1;

            if (eletmentIsAtomName) {
              const defaultRDKitAtomColor = (atom as HTMLElement).getAttribute('fill');

              if (defaultRDKitAtomColor) {
                const newColor = tinycolor(defaultRDKitAtomColor)
                  .setAlpha(0.5)
                  .toRgbString();

                (atom as HTMLElement).style.fill = newColor;
              }
            }
          }
        }
      });

      matches[index].bonds.forEach((bond) => {
        const svgBondsAndHighlights = svg.querySelectorAll('path.bond-' + bond);

        for (const element of svgBondsAndHighlights) {
          let newColor = color;

          if (color === 'transparent') {
            const alpha = 0.2;

            const fill = (element as HTMLElement).style.fill;

            // hack to fix bonds near stereocenter
            if (fill === 'rgb(255, 255, 255)') {
              (element as HTMLElement).style.fill = tinycolor(fill)
                .setAlpha(alpha)
                .toRgbString();
              (element as HTMLElement).style.filter = 'invert(1)';
            }

            // todo: check other type of bonds near stereocenter (looks like wifi sign)

            let stroke = (element as HTMLElement).style.stroke;
            const strokeHEX = tinycolor(stroke).toHex();

            const elementIsHighlight = strokeHEX.includes(defaultHighlightColor);

            if (elementIsHighlight) {
              stroke = '#EDEDED'; // to grey out the bond, use greish color for bond's highlight
            }

            newColor = tinycolor(stroke).setAlpha(alpha).toRgbString();

            (element as HTMLElement).style.stroke = newColor;
            (element as HTMLElement).style.fill = newColor;
          }

          let stroke = (element as HTMLElement).style.stroke;
          const strokeHEX = tinycolor(stroke).toHex();
          const elementIsHighlight = strokeHEX.includes(defaultHighlightColor);

          if (elementIsHighlight) {
            // here we want to change only highlighters, not bonds itself
            (element as HTMLElement).style.stroke = newColor;
          }
        }
      });
    }
  });

  return svg;
};

/**
 * Returns an SVG for a given molecule
 * @param RDKit RDKit object
 * @param smiles Structure in SMILES or MOLFILE format
 * @param coreSmilesArray List of structures fragments in SMILES or MOLFILE format to highlight in rendered structure
 * @param highlightAllCores Pass *true* if several cores should be highlighted otherwise only the first found core will be highlighted
 * @param alignStructuresList List of structures which are used for alignment
 */
export const smilesToSvg = (
  RDKit: Record<string, any>,
  smiles: string, // todo molstr: Smiles | Molfile
  coreSmilesArray?: StructureRendererCore[],
  highlightAllCores?: boolean,
  alignStructuresList?: string[]
): SVGSVGElement | null => {
  let mol: any;
  const alignStructures = alignStructuresList ? [...alignStructuresList] : [];

  try {
    mol = smilesToMolecule(RDKit, smiles);

    if (coreSmilesArray && coreSmilesArray.length) {
      const matches: Record<number, IMatch> = {};
      const BreakException = {};

      try {
        coreSmilesArray.forEach((coreSmiles, index) => {
          const smiles = typeof coreSmiles === 'string' ? coreSmiles : coreSmiles.core;
          const core = smilesToMolecule(RDKit, smiles, true);

          const alignIndex =
            alignStructures.findIndex((alignCore) => alignCore === smiles) ?? -1;

          // if we have an alignment core identical to highlight core
          if (alignIndex > -1) {
            mol.generate_aligned_coords(core, true);
            alignStructures.splice(alignIndex, 1);
          }

          const match = mol.get_substruct_match(core);

          core.delete(); // must be called before throw

          if (match.length > 2) {
            matches[index] = JSON.parse(match);

            if (!highlightAllCores) {
              throw BreakException;
            }
          }
        });
      } catch (error) {
        if (error !== BreakException) throw error;
      }

      // If there are still alignment cores
      try {
        alignStructures.forEach((alignStructure) => {
          const alignMol = smilesToMolecule(RDKit, alignStructure, true);

          mol.generate_aligned_coords(alignMol, true);

          alignMol.delete();
        });
      } catch (error) {
        if (error !== BreakException) throw error;
      }

      if (matches) {
        const match = Object.values(matches).reduce(
          (prev, curr) => {
            return {
              atoms: prev.atoms.concat(curr.atoms),
              bonds: prev.bonds.concat(curr.bonds),
            };
          },
          { atoms: [], bonds: [] }
        );

        /*
         * docs for MDetails object
         * https://www.rdkit.org/docs/cppapi/structRDKit_1_1MolDrawOptions.html
         * https://www.rdkit.org/docs/cppapi/MolDraw2DHelpers_8h_source.html
         */
        let mdetails = {
          //
          atoms: match.atoms,
          bonds: match.bonds,
          scaleHighlightBondWidth: true,
          // highlightColour: [0.9296875, 0.9296875, 0.9296875],
          flagCloseContactsDist: 1,
          highlightRadius: 0.3,
          fillHighlights: true,

          bondLineWidth: 1,

          symbolColour: [1, 1, 1, 1],
          // splitBonds: true,
          // singleColourWedgeBonds: true, // not work ?
          // centreMoleculesBeforeDrawing: false,
          // addBondIndices: true,
        };

        const svg = mol.get_svg_with_highlights(JSON.stringify(mdetails));
        const dom = new DOMParser().parseFromString(svg, 'image/svg+xml');
        const svgElement = dom.querySelector('svg');

        return svgElement ? colorSvg(svgElement, coreSmilesArray, matches) : null;
      }
    }

    const svg = mol.get_svg();
    const dom = new DOMParser().parseFromString(svg, 'image/svg+xml');

    return dom.querySelector('svg');
  } finally {
    if (mol) {
      mol.delete();
    }
  }
};

// todo molstr: Smiles | Molfile
export const getMOLBLOCK = (RDKit: IRDKitContext['RDKit'], smiles: string): string => {
  if (RDKit !== null) {
    try {
      const mol = RDKit.get_mol(smiles);
      const result = mol.get_v3Kmolblock();

      mol.delete();

      return result;
    } catch (err) {
      console.error(err);
    }
  }

  return '';
};

export const getSMILES = (RDKit: IRDKitContext['RDKit'], structure: string): string => {
  if (RDKit !== null) {
    try {
      const mol = RDKit.get_mol(structure);
      const result = mol.get_smiles();

      mol.delete();

      return result;
    } catch (err) {
      console.error(err);
    }
  }

  return '';
};
