import { StructureRendererProps } from '@discngine/moosa-models';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import React, { JSXElementConstructor } from 'react';

import { StructureRenderer } from '../StructureRenderer/StructureRenderer';

import styles from './StructureRendererWithPopover.module.less';

export interface StructureRendererWithPopoverProps extends StructureRendererProps {
  placement?: TooltipPlacement;
}

export type IStructureRendererWithProps =
  JSXElementConstructor<StructureRendererWithPopoverProps>;

export const StructureRendererWithPopover: IStructureRendererWithProps = (props) => {
  const structure = <StructureRenderer {...props} />;

  return (
    <>
      <Popover
        content={<div className={styles.popoverContent}>{structure}</div>}
        overlayClassName={styles.popover}
        placement={props.placement ?? 'bottomLeft'}
      >
        <div>{structure}</div>
      </Popover>
    </>
  );
};
