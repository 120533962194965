import { RefObject, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (ref: RefObject<HTMLDivElement>) => {
  const [isOverflow, setIsOverflow] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);
    }
  }, [ref]);

  return isOverflow;
};
