import { createContext, useContext } from 'react';

import { IAppInfo } from './serverModels/IAppInfo';
import { ICollectionResponse } from './serverModels/ICollectionResponse';
import { IDatasetMetaModel } from './serverModels/IDatasetMetaModel';
import { IDatasetRowWithScore } from './serverModels/IDatasetModel';
import { DecisionTreeId, IDecisionTree } from './serverModels/IDecisionTree';
import {
  IDesirabilityFunction,
  IScoringCalculationRequest,
  IScoringTemplate,
  IScoringTemplateRequest,
} from './serverModels/IScoringTemplate';
import {
  ISmartChartCreateResponse,
  ISmartChartModel,
  ISmartChartModelParams,
} from './serverModels/ISmartChartModel';

/* BE supports sorting by:
 * - $sort[propertyName]: 1 | -1
 *  where -1:desc | 1:asc
 * */
export type SortField<T, K extends keyof T = keyof T> = Partial<{
  [P in K as `$sort[${P extends string ? P : never}]`]: 1 | -1;
}>;

/* BE supports filtering by:
 * - ?versionName=version1 full match
 * - regexp ?versionName[$regex]=sion
 * */
export type FilterField<T, K extends keyof T = keyof T> = Partial<{
  [P in K as `${P extends string ? P : never}[$regex]`]: T[K];
}>;

export interface DefaultParams {
  limit?: number;
  skip?: number;
}

export interface IMoosaDataService
  extends IMoosaScoringTemplateDataService,
    IMoosaDecisionTreeDataService {
  // datasets
  getDatasetList(
    params?: DefaultParams &
      SortField<IDatasetMetaModel> &
      FilterField<IDatasetMetaModel> &
      Partial<IDatasetMetaModel>
  ): Promise<ICollectionResponse<IDatasetMetaModel>>;
  createDataset(params?: Omit<IDatasetMetaModel, '_id'>): Promise<IDatasetMetaModel>;
  getDatasetById(id: string): Promise<IDatasetMetaModel>;
  // to update an entity we need an ID and portion of updated data
  updateDatasetById(params: IDatasetMetaModel): Promise<IDatasetMetaModel>;
  deleteDatasetById(id: string): Promise<IDatasetMetaModel & { deletedAt: string }>;
  undeleteDatasetById(id: string): Promise<IDatasetMetaModel>;

  getDatasetMetadata(id: string): Promise<IDatasetMetaModel>;

  getDataWithScore(
    tableId: string,
    payload: IScoringCalculationRequest
  ): Promise<ICollectionResponse<IDatasetRowWithScore>>;

  deleteDatasetById(datasetId: string): Promise<IDatasetMetaModel>;

  undeleteDatasetById(datasetId: string): Promise<IDatasetMetaModel>;

  assignScoringTemplate(
    tableId: string,
    scoringTemplateId: string | null
  ): Promise<never>;

  patchComputedColumns(tableId: string, computed: any[]): Promise<never>;

  smartChartCreate(
    datasetId: string,
    payload: ISmartChartModelParams
  ): Promise<ISmartChartCreateResponse>;

  getSmartChart(datasetId: string): Promise<ISmartChartModel>;

  /*** Appinfo ***/

  getInfoAboutApplication(): Promise<IAppInfo[]>;
}

export interface IMoosaScoringTemplateDataService {
  getScoringTemplates(
    params?: DefaultParams
  ): Promise<ICollectionResponse<IScoringTemplate>>;
  getScoringTemplateVersions(
    parentId: string
  ): Promise<ICollectionResponse<IScoringTemplate>>;

  createScoringTemplate(payload: IScoringTemplateRequest): Promise<IScoringTemplate>;
  getScoringTemplate(templateId: string): Promise<IScoringTemplate>;
  // to update an entity we need an ID and portion of updated data
  updateScoringTemplate(
    templateId: string,
    payload: IScoringTemplateRequest
  ): Promise<IScoringTemplate>;
  deleteScoringTemplate(
    templateId: string
  ): Promise<IScoringTemplate & { deletedAt: string }>;
  undeleteScoringTemplate(templateId: string): Promise<IScoringTemplate>;
  checkScoringTemplateName(name: string): Promise<{ success: boolean }>;

  createDesirabilityFunction(
    payload: Omit<IDesirabilityFunction, '_id'>
  ): Promise<IDesirabilityFunction>;

  // desirability functions
  getDesirabilityFunctionById(id: string): Promise<IDesirabilityFunction>;
  updateDesirabilityFunctionById(
    // to update an entity we need an ID and portion of updated data
    params: Partial<IDesirabilityFunction> & { _id: string }
  ): Promise<IDesirabilityFunction>;
  deleteDesirabilityFunctionById(
    id: string
  ): Promise<IDesirabilityFunction & { deletedAt: string }>;
  undeleteDesirabilityFunctionById(id: string): Promise<IDesirabilityFunction>;
  getDesirabilityFunctionList(
    params?: DefaultParams &
      SortField<IDesirabilityFunction> &
      FilterField<IDesirabilityFunction> &
      Partial<IDesirabilityFunction>
  ): Promise<ICollectionResponse<IDesirabilityFunction>>;

  checkDesirabilityFunctionName(payload: {
    name: string;
    _id?: string;
  }): Promise<{ success: boolean }>;
}

export interface IMoosaDecisionTreeDataService {
  /**
   * parentId == undefined - returns list of initial versions of decision trees
   * parentId is id of initial version - returns all versions of this decision tree
   *
   */
  getDecisionTrees(
    params: DefaultParams & { parentId?: DecisionTreeId }
  ): Promise<ICollectionResponse<IDecisionTree>>;

  createDecisionTree(payload: Omit<IDecisionTree, '_id'>): Promise<IDecisionTree>;

  checkDecisionTreeName(payload: {
    name: string;
    versionName?: string;
    parentId?: string;
    _id?: string;
  }): Promise<{ success: boolean }>;

  getDecisionTree(decisionTreeId: DecisionTreeId): Promise<IDecisionTree>;

  // to update an entity we need an ID and portion of updated dat
  updateDecisionTree(
    decisionTreeId: DecisionTreeId,
    payload: Partial<IDecisionTree> & { _id: string }
  ): Promise<IDecisionTree>;

  deleteDecisionTree(
    decisionTreeId: DecisionTreeId
  ): Promise<IDecisionTree & { deletedAt: string }>;

  undeleteDecisionTree(decisionTreeId: DecisionTreeId): Promise<IDecisionTree>;
}

export const MoosaDataContext = createContext<IMoosaDataService | null>(null);

export function useMoosaDataContext(): IMoosaDataService {
  const context = useContext(MoosaDataContext);

  if (!context) {
    throw Error('IMoosaDataService is not provided in MoosaDataContext');
  }

  return context;
}
