import {
  FieldType,
  EMoosaSarConfigImageSize,
  SCORE_COLUMN_ID,
} from '@discngine/moosa-models';
import { MoosaSarTableConfig, HandleSetSarConfig } from '@discngine/moosa-sar-table';
import {
  selectSarColumns,
  setSarColumns,
  setSarStructure,
  selectSarImageSize,
  setSarImageSize,
  selectSarStructure,
  selectColorizingTextMode,
  setColorizingTextMode,
} from '@discngine/moosa-store/sarConfig';
import { selectScores } from '@discngine/moosa-store/scores';
import { selectScoringTemplate } from '@discngine/moosa-store/scoringTemplate';
import {
  selectTableColumnTypes,
  selectTableColumnLabels,
} from '@discngine/moosa-store/tableConfig';
import { selectTableColumnsMap } from '@discngine/moosa-store/tableInfo';
import { RDKitContext, StructureRenderer } from '@discngine/moosa-structure-renderer';
import React, { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useRDKitProvider } from '../../../components/useRDKitProvider';

const StructureEditor = React.lazy(() => import('@discngine/moosa-structure-editor'));

export const SarTableProperties = () => {
  const tableColumnsMap = useSelector(selectTableColumnsMap);
  const template = useSelector(selectScoringTemplate);
  const structure = useSelector(selectSarStructure);
  const isColorizingTextMode = useSelector(selectColorizingTextMode);
  const scores = useSelector(selectScores);

  const dispatch = useDispatch();
  const columnTypes: Record<string, string> = useSelector(
    selectTableColumnTypes,
    shallowEqual
  );

  const sarConfigColumns = useSelector(selectSarColumns);
  const imageSize = useSelector(selectSarImageSize);

  const columns = useMemo(() => {
    const columns = Object.keys(columnTypes)
      .filter((name) => {
        const type = columnTypes[name];

        return (
          type === FieldType.Number ||
          type === FieldType.String ||
          type === FieldType.Computed
        );
      })
      .map((name) => name);

    if (Object.keys(scores)) {
      columns.splice(0, 0, SCORE_COLUMN_ID);
    }

    return columns;
  }, [columnTypes, scores]);

  const handleColumns = useCallback(
    (columns) => {
      dispatch(setSarColumns(columns));
    },
    [dispatch]
  );

  const handleStructure = useCallback(
    (structure) => {
      dispatch(setSarStructure(structure));
    },
    [dispatch]
  );

  const handleImageSizeChange = useCallback(
    (value: EMoosaSarConfigImageSize) => {
      dispatch(setSarImageSize(value));
    },
    [dispatch]
  );

  const toggleColorizingTableMode = useCallback(() => {
    dispatch(setColorizingTextMode(!isColorizingTextMode));
  }, [dispatch, isColorizingTextMode]);

  const RDKit = useRDKitProvider();

  const handleSetSettings: HandleSetSarConfig = ({
    columns,
    coreStructures: structure,
    imageSize,
    isColorizingTextMode,
  }) => {
    handleColumns(columns);
    handleStructure(structure);
    handleImageSizeChange(imageSize);
    dispatch(setColorizingTextMode(isColorizingTextMode));
  };

  const columnLabelMap = useSelector(selectTableColumnLabels);

  return (
    <RDKitContext.Provider value={RDKit}>
      <MoosaSarTableConfig
        availableColumns={columns}
        columnLabelMap={columnLabelMap}
        columns={sarConfigColumns}
        coreStructures={structure}
        imageSize={imageSize}
        isColorizingTextMode={isColorizingTextMode}
        setSettings={handleSetSettings}
        showConfigImportExport={true}
        StructureEditor={StructureEditor}
        structureRenderer={StructureRenderer}
        tableColumnsMap={tableColumnsMap}
        templateColumnsIds={template.order}
        toggleColorizingTableMode={toggleColorizingTableMode}
        onColumnsChange={handleColumns}
        onImageSizeChange={handleImageSizeChange}
        onStructureChange={handleStructure}
      />
    </RDKitContext.Provider>
  );
};
