import {
  FieldType,
  DesirabilityFunctionRules,
  DiscreteFunctionParams,
  IDiscretePoint,
  ILineDiscretePoint,
  ILinePoint,
  IRange,
  SortFunctionTableType,
  IColumnMetaInfo,
  ServerLinePoint,
  ServerDiscretePoint,
} from '@discngine/moosa-models';
import { v4 as uuid } from 'uuid';

import {
  checkXBoundaries,
  checkYBoundaries,
  convertToServerNeededFormat,
  getRangeForLinearApprox,
  getValueFromLinearApprox,
  toLine,
  updateLinePoint,
} from './DiscreteRulesUtils';

let initialIdCounter = 1;

function sortPoints(
  type: FieldType,
  points: ILineDiscretePoint[],
  xSort: SortFunctionTableType,
  ySort: SortFunctionTableType
): ILineDiscretePoint[] {
  if (type === FieldType.Number) {
    points.sort((point, nextPoint) => {
      if (xSort === SortFunctionTableType.Ascending) {
        return Number(point.x) - Number(nextPoint.x);
      } else if (xSort === SortFunctionTableType.Descending) {
        return Number(nextPoint.x) - Number(point.x);
      } else if (ySort === SortFunctionTableType.Descending) {
        return Number(nextPoint.y) - Number(point.y);
      } else if (ySort === SortFunctionTableType.Ascending) {
        return Number(point.y) - Number(nextPoint.y);
      } else {
        return 0;
      }
    });
  }

  if (type === FieldType.String) {
    points.sort(function (point, nextPoint) {
      if (xSort !== SortFunctionTableType.None) {
        const nameA = point.x.toString().toLowerCase();
        const nameB = nextPoint.x.toString().toLowerCase();

        if (xSort === SortFunctionTableType.Ascending) {
          if (nameA < nameB) {
            return -1;
          }

          if (nameA > nameB) {
            return 1;
          }

          return 0;
        } else if (xSort === SortFunctionTableType.Descending) {
          if (nameA > nameB) {
            return -1;
          }

          if (nameA < nameB) {
            return 1;
          }

          return 0;
        }
      } else if (ySort !== SortFunctionTableType.None) {
        if (ySort === SortFunctionTableType.Ascending) {
          return Number(point.y) - Number(nextPoint.y);
        } else if (ySort === SortFunctionTableType.Descending) {
          return Number(nextPoint.y) - Number(point.y);
        } else {
          return 0;
        }
      }

      return 0;
    });
  }

  return points;
}

export const discreteFunctionRules: DesirabilityFunctionRules<DiscreteFunctionParams | null> =
  {
    init(metadata: IColumnMetaInfo): DiscreteFunctionParams | null {
      if (metadata.isDiscreteColumn) {
        const initPoints = metadata.statistics!.textCategories.map((point) => {
          const discretePoint: ILineDiscretePoint = {
            id: uuid(),
            x: point.value,
            y: 0,
            originalPointIndex: initialIdCounter++,
            count: point.count,
            isAddedByUser: false,
            isNotInFunction: false,
            mark: true,
          };

          return discretePoint;
        });

        const params: DiscreteFunctionParams = {
          points: sortPoints(
            metadata.type,
            initPoints,
            SortFunctionTableType.Ascending,
            SortFunctionTableType.None
          ),
        };

        return params;
      }

      return null;
    },
    getValue(xVal: number, params: DiscreteFunctionParams): number {
      return getValueFromLinearApprox(xVal, params.points);
    },
    getRange(params: DiscreteFunctionParams, metadata: IColumnMetaInfo): IRange {
      return getRangeForLinearApprox(params.points, metadata);
    },
    toLine(params: DiscreteFunctionParams, range: IRange): ILineDiscretePoint[] {
      return toLine(params.points, range);
    },
    toServerTemplate(params: DiscreteFunctionParams): ServerDiscretePoint[] {
      return convertToServerNeededFormat(params.points);
    },
    fromServerTemplate(
      points: ServerLinePoint[],
      metadata: IColumnMetaInfo
    ): DiscreteFunctionParams {
      // todo check that it is correct column type
      const textCategories = metadata?.statistics?.textCategories || [];

      const pointsMap = new Map<string, IDiscretePoint>();

      points.forEach((point) => {
        // initialize like the point exists in function and absent in dataset
        const discretePoint: ILineDiscretePoint = {
          id: uuid(),
          x: point.x,
          y: point.y,
          originalPointIndex: initialIdCounter++,
          count: 0,
          isAddedByUser: true,
          isNotInFunction: false,
          mark: true,
        };

        pointsMap.set(String(point.x), discretePoint);
      });

      textCategories.forEach((category) => {
        const point = pointsMap.get(String(category.value));

        if (point) {
          // point both in function and in dataset
          point.count = category.count;
          point.isAddedByUser = false;
        } else {
          // point exists in dataset and absent in function
          const discretePointNotInFunction: ILineDiscretePoint = {
            id: uuid(),
            x: category.value,
            y: 0,
            originalPointIndex: initialIdCounter++,
            count: category.count,
            isAddedByUser: false,
            isNotInFunction: true,
            mark: false,
          };

          pointsMap.set(String(category.value), discretePointNotInFunction);
        }
      });

      const discretePoints = [...pointsMap.values()];

      return {
        points: discretePoints,
      };
    },
    movePoint(params: DiscreteFunctionParams, point: ILineDiscretePoint) {
      return updateLinePoint(params.points, point) as DiscreteFunctionParams;
    },
    addPoint(
      params: DiscreteFunctionParams,
      point: ILinePoint,
      fieldType,
      xSort,
      ySort
    ): DiscreteFunctionParams {
      const newParams: DiscreteFunctionParams = {
        points: sortPoints(
          fieldType,
          [
            ...params.points,
            {
              id: uuid(),
              x: checkXBoundaries(point.x),
              y: checkYBoundaries(point.y),
              originalPointIndex: initialIdCounter++,
              isAddedByUser: true,
              count: 0,
              mark: true,
            },
          ],
          xSort,
          ySort
        ),
      };

      return newParams;
    },
    removePoint(params: DiscreteFunctionParams, index: number) {
      const pointToRemove = params.points.find(
        (point) => point.originalPointIndex === index
      )!;

      const newParams: DiscreteFunctionParams = {
        points: params.points.filter((point) => point !== pointToRemove),
      };

      return newParams;
    },
  };
