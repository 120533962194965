import { ColumnId, PortGroupingType } from '@discngine/moosa-models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDecisoinTreeConfigState } from './types';

const initialState: IDecisoinTreeConfigState = {
  columnGroupId: null,
  defaultPortGrouping: PortGroupingType.Regular,
};

export const decisionTreeConfigSlice = createSlice({
  name: 'decisionTreeConfig',
  initialState,
  reducers: {
    resetDecisionTreeConfig(state) {
      [state.columnGroupId, state.defaultPortGrouping] = [
        initialState.columnGroupId,
        initialState.defaultPortGrouping,
      ];
    },
    setDecisionTreeGroupColumnId(state, action: PayloadAction<ColumnId | null>) {
      state.columnGroupId = action.payload;
    },
    setDecisionTreeDefaultPortGrouping(
      state,
      action: PayloadAction<PortGroupingType | null>
    ) {
      state.defaultPortGrouping = action.payload;
    },
  },
});

export const {
  resetDecisionTreeConfig,
  setDecisionTreeGroupColumnId,
  setDecisionTreeDefaultPortGrouping,
} = decisionTreeConfigSlice.actions;
