import { CheckOutlined, DeleteFilled } from '@ant-design/icons';
import {
  AVAILABLE_COLORS_FOR_SAR_COLORIZING,
  IRGBColor,
  isEqualColor,
  rgbToString,
} from '@discngine/moosa-common';
import React, { FC, useCallback, useMemo } from 'react';

import styles from './AvailableColorsPanel.module.less';

export interface IAvailableColorsPanel {
  selectedColor: IRGBColor | null;
  onColorChange: (newColor: IRGBColor | null, indexOfSelectedColor: number) => void;
  onRemoveColor: (indexOfSelectedColor: number) => void;
  indexOfSelectedColor: number;
  isPossibleToRemoveColor: boolean;
  showFullColorsPalette: boolean;
}

export const AvailableColorsPanel: FC<IAvailableColorsPanel> = React.memo(
  ({
    selectedColor,
    onColorChange,
    onRemoveColor,
    indexOfSelectedColor,
    isPossibleToRemoveColor,
    showFullColorsPalette,
  }) => {
    const onChange = useCallback(
      (color, indexOfSelectedColor) => {
        onColorChange(color, indexOfSelectedColor);
      },
      [onColorChange]
    );

    const onRemove = useCallback(() => {
      onRemoveColor(indexOfSelectedColor);
    }, [onRemoveColor, indexOfSelectedColor]);

    const currentColorsPalette = useMemo(() => {
      if (!showFullColorsPalette) {
        return AVAILABLE_COLORS_FOR_SAR_COLORIZING.map((colorsColumn) => [
          colorsColumn[0],
        ]);
      }

      return AVAILABLE_COLORS_FOR_SAR_COLORIZING;
    }, [showFullColorsPalette]);

    return (
      <div className={styles.root}>
        <div className={styles.availableColors}>
          {currentColorsPalette.map((colorsColumn: IRGBColor[], index: number) => (
            <div key={index} className={styles.colorsColumn}>
              {colorsColumn.map((color, colorIndex) => (
                <div
                  key={colorIndex}
                  className={`${styles.colorItem} ${styles.discreteParameter}`}
                  style={{ background: rgbToString(color) }}
                  onClick={() => onChange(color, indexOfSelectedColor)}
                >
                  {selectedColor && isEqualColor(selectedColor, color) && (
                    <CheckOutlined className={styles.checkIcon} />
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        {isPossibleToRemoveColor && (
          <DeleteFilled className={styles.deleteIcon} onClick={onRemove} />
        )}
      </div>
    );
  }
);
