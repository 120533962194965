import {
  IDatasetMetaField,
  IColumnMetaInfo,
  IHistogramBar,
} from '@discngine/moosa-models';

export function toColumnMetaInfo(fields: IDatasetMetaField[]): IColumnMetaInfo[] {
  return fields.map((field) => {
    const histogramMax = Math.max(...(field.statistics?.histogram || [])) || 1; // todo temporary fix, wait for BE
    let histogramBars: IHistogramBar[] = [];
    let dataWidth = 0;
    const isDiscreteColumn = field.statistics?.textCategories !== null;

    if (field.statistics && field.statistics.histogram) {
      const min = field.statistics.min;
      const max = field.statistics.max;
      const step = field.statistics.histogramStep || 1; // todo temporary fix, wait for BE

      dataWidth = max - min;

      histogramBars = field.statistics.histogram.map((yVal, index, arr) => {
        const bar: IHistogramBar = {
          x0: min + step * index,
          x: index === arr.length - 1 ? max : min + step * (index + 1),
          y0: 0,
          y: arr.length !== 0 ? yVal : yVal || 1, // todo temporary fix, wait for BE
        };

        return bar;
      });
    }
    const meta: IColumnMetaInfo = {
      ...field,
      histogramMax,
      histogramBars,
      dataWidth,
      isDiscreteColumn,
    };

    return meta;
  });
}
