import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from './PropertiesSelector.module.less';

export interface IPropertiesSelectorProps {
  availableColumns: string[];
  onSelect: (property: string) => void;
  selectedColumns: string[];
}

export const PropertiesSelector: FC<IPropertiesSelectorProps> = ({
  availableColumns,
  onSelect,
  selectedColumns,
}) => {
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Multiple properties</h3>
      <Dropdown
        overlay={
          <>
            <PerfectScrollbar
              className={styles.menuContainer}
              options={{ suppressScrollX: true }}
            >
              <Menu className={styles.menu} selectable={false}>
                {availableColumns.map((item) => (
                  <Menu.Item key={item} onClick={() => () => onSelect(item)}>
                    <Checkbox
                      checked={selectedColumns.includes(item)}
                      onChange={() => onSelect(item)}
                    >
                      <span> {item}</span>
                    </Checkbox>
                  </Menu.Item>
                ))}
              </Menu>
            </PerfectScrollbar>
          </>
        }
        trigger={['click']}
      >
        <Button className={styles.dropdownBtn} type="text">
          <div className={styles.dropdownBtnTitle}>
            Properties
            <div className={styles.numberOfSelectedColumns}>{selectedColumns.length}</div>
          </div>
          <DownOutlined className={styles.dropdownArrow} />
        </Button>
      </Dropdown>
    </div>
  );
};
