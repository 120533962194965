import {
  DatasetRowId,
  DesirabilityFunctionType,
  FieldType,
  IMoosaDataService,
  IPoint2D,
  IScoringCalculationRequest,
  IScoringTemplates,
  IRowsAndScores,
  ISortMode,
  ITableDataSubRoot,
  IScoringTemplate,
  MAX_DATASET,
  PAGE_SIZE,
  ColumnId,
  IScoringTemplateItem,
} from '@discngine/moosa-models';
import { Action, ThunkAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import {
  appendScoresAt,
  IScoreMap,
  IScoreSubRoot,
  selectScores,
  setScoredDataset,
} from '@discngine/moosa-store/scores';
import {
  IScoringTemplateData,
  selectScoringFunction,
  IScoringTemplateSubRoot,
  ScoringFunction,
} from '@discngine/moosa-store/scoringTemplate';
import {
  DesirabilityFunctionLineSerialization,
  scoringFunctionToPayload,
} from '@discngine/moosa-store/scoringTemplate';
import {
  IColumnMetaInfo,
  ITableInfoSubRoot,
  selectTableInfo,
} from '@discngine/moosa-store/tableInfo';

import { convertTemplateToTableDataType } from '../scoringTemplate';

import { selectTableSort } from './tableData.selectors';
import { datasetRowsToInternal } from './tableData.service';
import {
  appendDataAt,
  setPaginator,
  setRawDataset,
  tableSelectionChange,
  toggleTableChunkLoading,
} from './tableData.slice';

type TableConfigThunk<T = void> = ThunkAction<
  T,
  ITableDataSubRoot & ITableInfoSubRoot & IScoreSubRoot & IScoringTemplateSubRoot,
  unknown,
  Action<string>
>;

export function fetchDataWithScores(
  template: IScoringTemplateData,
  getDataWithScore: IMoosaDataService['getDataWithScore'],
  page = 1,
  pageSize = PAGE_SIZE,
  comparisonTemplate?: IScoringTemplate,
  pinnedSubstances?: string[]
): TableConfigThunk {
  return async (dispatch, getState) => {
    console.info('fetchDataWithScores', page, pageSize);
    dispatch(toggleTableChunkLoading(true));
    const offset = (page - 1) * pageSize;
    const sort = selectTableSort(getState());

    try {
      const { rows, scores, comparisonScores, total } = await dispatch(
        fetchCurrentDataWithScores(
          template,
          getDataWithScore,
          page,
          pageSize,
          sort,
          undefined,
          comparisonTemplate,
          pinnedSubstances
        )
      );

      const keys = Object.keys(scores);

      batch(function setTableData() {
        dispatch(appendScoresAt({ offset, scores, comparisonScores }));
        dispatch(appendDataAt({ offset, rows, total }));
        dispatch(setPaginator({ page, pageSize, keys }));
        dispatch(toggleTableChunkLoading(false));
      });

      console.info('COMPLETE fetchDataWithScores', keys.length);
    } catch (error) {
      dispatch(toggleTableChunkLoading(false));
    }
  };
}

export function fetchScoredDataset(
  template: IScoringTemplateData,
  getDataWithScore: IMoosaDataService['getDataWithScore']
): TableConfigThunk {
  return async (dispatch, getState) => {
    console.info('fetchDataset');

    const state = getState();
    const sort = selectTableSort(state);

    try {
      const { rows, scores, total } = await dispatch(
        fetchCurrentDataWithScores(template, getDataWithScore, 1, MAX_DATASET, sort)
      );

      batch(() => {
        dispatch(setScoredDataset(scores));

        if (!state.tableData.isFull) {
          dispatch(setRawDataset({ rows, total }));
        }
      });

      console.info('COMPLETE fetchDataWithScores', rows.scores);
    } catch (error) {
      console.info(error);
    }
  };
}

export function fetchCurrentDataWithScores(
  template: IScoringTemplateData,
  getDataWithScore: IMoosaDataService['getDataWithScore'],
  page = 1,
  pageSize = PAGE_SIZE,
  sort?: ISortMode,
  columns?: ColumnId[],
  comparisonTemplate?: IScoringTemplate,
  pinnedSubstances?: string[]
): TableConfigThunk<Promise<IRowsAndScores>> {
  return async function fetchAllDataWithScoresThunk(
    dispatch,
    getState
  ): Promise<IRowsAndScores> {
    const state = getState();
    const offset = (page - 1) * pageSize;
    const tableInfo = selectTableInfo(state);
    const scoringFunction = selectScoringFunction(state);

    const tableId = tableInfo.id;
    const { columnsMap, columnIds } = tableInfo;

    return fetchDataHelper(
      {
        tableId,
        template,
        columnsMap,
        columnIds,
        offset,
        pageSize,
        sort,
        columns,
        comparisonTemplate,
        doNotTransferData: state.tableData.isFull,
        scoringFunction,
        pinnedSubstances,
      },
      getDataWithScore
    );
  };
}

const getApplicableForScoringColumns = (
  columns: IScoringTemplateItem[],
  template: IScoringTemplateData,
  columnsMap: Record<string, IColumnMetaInfo>
): IScoringTemplateItem[] => {
  return columns.filter((col) => {
    const isDiscreteInDataset = columnsMap[col.columnName].isDiscreteColumn;
    const isDiscreteInTemplate =
      !!template.func[col.columnName].functionParams[DesirabilityFunctionType.discrete];
    const typeInDataset = columnsMap[col.columnName].type;
    const isDiscreteStringFunctionInTemplate = col.isDiscreteStringFunction;

    if (isDiscreteInTemplate || isDiscreteInDataset) {
      const columnIsApplicableToDataset =
        (isDiscreteInDataset === isDiscreteInTemplate &&
          ((isDiscreteStringFunctionInTemplate && typeInDataset === FieldType.String) ||
            (!isDiscreteStringFunctionInTemplate &&
              typeInDataset === FieldType.Number))) ||
        typeInDataset === FieldType.Computed; // because computed column can be only numeric type

      return columnIsApplicableToDataset;
    }

    return true;
  });
};

async function fetchDataHelper(
  options: {
    tableId: string;
    template: IScoringTemplateData;
    columnsMap: Record<string, IColumnMetaInfo>; // TODO to be removed
    columnIds: string[]; // TODO to be removed
    offset?: number;
    pageSize?: number;
    sort?: ISortMode;
    columns?: ColumnId[]; // list of columns to return, if empty - return all
    comparisonTemplate?: IScoringTemplate;
    doNotTransferData: boolean;
    scoringFunction?: ScoringFunction;
    pinnedSubstances?: string[];
  },
  getDataWithScore: IMoosaDataService['getDataWithScore']
): Promise<IRowsAndScores> {
  const {
    tableId,
    template,
    columnsMap,
    columnIds,
    offset,
    pageSize,
    sort,
    columns,
    comparisonTemplate,
    doNotTransferData,
    scoringFunction,
    pinnedSubstances,
  } = options;
  const templatePayload = scoringFunctionToPayload(
    'scoring-calculation',
    template,
    columnsMap,
    DesirabilityFunctionLineSerialization.LINE
  );

  const applicableMainTemplateColumns = getApplicableForScoringColumns(
    templatePayload.columns!,
    template,
    columnsMap
  );

  templatePayload.columns = applicableMainTemplateColumns.map((col) => {
    const newCol = { ...col };

    if (
      newCol.isDiscreteStringFunction &&
      typeof newCol.missingValue?.replaceWithY === 'string' &&
      newCol.missingValue?.replaceWithColumn
    ) {
      const replaceWithYValue = scoringFunction![newCol.missingValue?.replaceWithColumn]
        ? scoringFunction![
            newCol.missingValue?.replaceWithColumn
          ].functionParams.discrete?.points.find(
            (point) => point.x === newCol.missingValue?.replaceWithY
          )!.y
        : null;

      newCol.missingValue = {
        ...col.missingValue,
        replaceWithY: replaceWithYValue,
      };
    } else if (
      newCol.isDiscreteStringFunction &&
      typeof newCol.missingValue?.replaceWithY === 'string'
    ) {
      newCol.missingValue = {
        ...col.missingValue,
        replaceWithY: col.desirability.points.find(
          (point: IPoint2D) => point.x === col.missingValue?.replaceWithY
        )?.y,
      };
    }

    // This property is not required for scoring calculations
    delete newCol.isDiscreteStringFunction;

    return newCol;
  });

  // TODO need to discuss this code
  let templates: IScoringTemplates = {
    mainTemplate: templatePayload,
  };

  if (comparisonTemplate) {
    const comparisonTemplateData = convertTemplateToTableDataType(
      comparisonTemplate,
      columnsMap
    );
    const comparisonTemplatePayload = scoringFunctionToPayload(
      'scoring-calculation',
      comparisonTemplateData!,
      columnsMap,
      DesirabilityFunctionLineSerialization.LINE
    );

    const applicableComparisonTemplateColumns = getApplicableForScoringColumns(
      comparisonTemplatePayload.columns!,
      comparisonTemplateData!,
      columnsMap
    );

    comparisonTemplatePayload.columns = applicableComparisonTemplateColumns.map((col) => {
      const newCol = { ...col };

      if (newCol.isDiscreteStringFunction && newCol.missingValue?.replaceWithY) {
        newCol.missingValue = {
          ...col.missingValue,
          replaceWithY: col.desirability.points.find(
            (point: IPoint2D) => point.x === col.missingValue?.replaceWithY
          )?.y,
        };
      }
      // This property is not required for scoring calculations
      delete newCol.isDiscreteStringFunction;

      return newCol;
    });

    // TODO need to discuss and fix
    // This property is not required for scoring calculations
    delete comparisonTemplatePayload.parentId;
    delete comparisonTemplatePayload.description;
    delete comparisonTemplatePayload.versionName;
    templates.templateForComparison = comparisonTemplatePayload;
  }

  const request: IScoringCalculationRequest = {
    templates,
    limit: pageSize,
    skip: offset,
    sort: sort,
    select: doNotTransferData ? [] : columns,
    includeRows: pinnedSubstances || [],
  };

  const res = await getDataWithScore(tableId, request);

  const data = [...res.data, ...res.includedRows];

  const rows = datasetRowsToInternal(columnIds, offset || 0, data);
  const scores: IScoreMap = {};
  const comparisonScores: IScoreMap = {};

  data.forEach((el) => {
    scores[el.id] = {
      value: el.scores['mainTemplate'].total,
      factors: el.scores['mainTemplate'].columns,
      rawValues: el.scores['mainTemplate'].rawValues,
      reasons: el.scores['mainTemplate'].reasons,
      measurementError: el.scores['mainTemplate'].measurementError,
      totalMeasurementError: el.scores['mainTemplate'].totalMeasurementError,
      missingColumns: el.scores['mainTemplate'].missingColumns,
    };

    if (el.scores['templateForComparison']) {
      comparisonScores[el.id] = {
        value: el.scores['templateForComparison'].total,
        factors: el.scores['templateForComparison'].columns,
        rawValues: el.scores['templateForComparison'].rawValues,
        reasons: el.scores['templateForComparison'].reasons,
        measurementError: el.scores['templateForComparison'].measurementError,
        totalMeasurementError: el.scores['templateForComparison'].totalMeasurementError,
        missingColumns: el.scores['templateForComparison'].missingColumns,
      };
    }
  });

  return { rows, scores, comparisonScores, total: res.total };
}

export function selectionChange(
  rowId: DatasetRowId,
  selected: boolean
): TableConfigThunk {
  return (dispatch, getState) => {
    const state = getState();
    const scores = selectScores(state);
    const score = scores[rowId];

    if (score?.value) {
      dispatch(tableSelectionChange({ rowId: rowId, selected }));
    }
  };
}
