import {
  FieldType,
  SpecialColumn,
  ITableConfigSubRoot,
  RowSizes,
  IColumnHeader,
  IColumnLabelMap,
} from '@discngine/moosa-models';
import { createSelector } from '@reduxjs/toolkit';

import { selectScoringColumnOrder } from '@discngine/moosa-store/scoringTemplate';
import { selectTableId } from '@discngine/moosa-store/tableInfo';

const selectColumnWidths = (state: ITableConfigSubRoot) => state.tableConfig.columnWidths;

const selectColumnOrder = (state: ITableConfigSubRoot) => state.tableConfig.columnOrder;

const selectColumnNames = (state: ITableConfigSubRoot) => state.tableConfig.columnNames;

const selectColumnVisibility = (state: ITableConfigSubRoot) =>
  state.tableConfig.columnVisibility;

const selectColumnTypes = (state: ITableConfigSubRoot) => state.tableConfig.columnTypes;

const selectColumnIsDiscreteFlags = (state: ITableConfigSubRoot) =>
  state.tableConfig.columnIsDiscreteFlags;

const columnColumnIsDiscreteStringFlags = (state: ITableConfigSubRoot) =>
  state.tableConfig.columnIsDiscreteStringFlags;

const selectRowsSize = (state: ITableConfigSubRoot) => state.tableConfig.rowsSize;

const selectCellBackgroundMode = (state: ITableConfigSubRoot) =>
  state.tableConfig.cellBackgroundMode;

export const PINNED_COLUMNS = [
  FieldType.Structure,
  FieldType.ID,
  SpecialColumn.Score,
  SpecialColumn.RowNumber,
  SpecialColumn.Radar,
  SpecialColumn.Missing,
  SpecialColumn.Comparison,
];

export const selectUseTemplateColumns = (state: ITableConfigSubRoot) =>
  state.tableConfig.useTemplateColumns;

export const selectTableRowsSize = createSelector(
  selectTableId,
  selectRowsSize,
  (id, map) => map[id!] || RowSizes.MEDIUM
);

export const selectTableColumnWidths = createSelector(
  selectTableId,
  selectColumnWidths,
  (id, map) => map[id!] || {}
);

export const selectTableColumnOrder = createSelector(
  selectTableId,
  selectColumnOrder,
  (id, map) => map[id!] || []
);

export const selectTableColumnNames = createSelector(
  selectTableId,
  selectColumnNames,
  (id, map) => map[id!] || {}
);

export const selectTableColumnLabels = createSelector(selectTableColumnNames, (map) => {
  const result: IColumnLabelMap | undefined = {};

  Object.keys(map).forEach((key) => {
    result[key] = { label: map[key] };
  });

  return result;
});

export const selectTableColumnVisibility = createSelector(
  selectTableId,
  selectColumnVisibility,
  (id, map) => map[id!]
);

export const selectTableColumnTypes = createSelector(
  selectTableId,
  selectColumnTypes,
  (id, map) => map[id!]
);

export const selectTableColumnIsDiscreteFlags = createSelector(
  selectTableId,
  selectColumnIsDiscreteFlags,
  (id, map) => map[id!]
);

export const selectTableColumnIsDiscreteStringFlags = createSelector(
  selectTableId,
  columnColumnIsDiscreteStringFlags,
  (id, map) => map[id!]
);

export const selectTableCellBackgroundMode = createSelector(
  selectTableId,
  selectCellBackgroundMode,
  (id, map) => map[id!]
);

export const selectColumnHeaders = createSelector(
  selectTableColumnOrder,
  selectTableColumnNames,
  selectTableColumnTypes,
  selectTableColumnIsDiscreteFlags,
  selectTableColumnIsDiscreteStringFlags,
  (order, names, types, isDiscreteColumn, isDiscreteStringColumn) => {
    const columns: IColumnHeader[] = order.map((id) => ({
      columnId: id,
      title: names[id],
      type: types[id],
      isDiscreteColumn: isDiscreteColumn[id],
      isDiscreteStringColumn: isDiscreteStringColumn[id],
    }));

    return columns;
  }
);

export const selectTemplateColumnHeaders = createSelector(
  selectTableColumnOrder,
  selectTableColumnNames,
  selectTableColumnTypes,
  selectScoringColumnOrder,
  (order, names, types, templateOrder) => {
    const pinned = order.filter((col) => PINNED_COLUMNS.includes(types[col]));

    const columns = [...pinned, ...templateOrder].map((id) => ({
      columnId: id,
      title: names[id],
      type: types[id],
    }));

    return columns;
  }
);

export const selectVisibleColumnHeaders = createSelector(
  selectColumnHeaders,
  selectTableColumnVisibility,
  (columnHeaders, visible) => {
    return columnHeaders.filter((column) => visible[column.columnId]);
  }
);

export const selectIsComputedColAdding = (state: ITableConfigSubRoot) =>
  state.tableConfig.isComputedColAdding;

export const selectIsComparisonTemplateSelectorVisible = (state: ITableConfigSubRoot) =>
  state.tableConfig.isComparisonTemplateSelectorVisible;

export const selectComputedColEditing = (state: ITableConfigSubRoot) =>
  state.tableConfig.computedColEditing;
