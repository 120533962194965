import {
  createAsyncThunk,
  createSlice,
  Draft,
  PayloadAction,
  unwrapResult,
} from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DataProvider,
  GenericItem,
  getNamedObject,
  ListResponse,
  UnwrappableAction,
} from '@discngine/moosa-store/common';

import { selectItemById, selectResult, selectSkip, selectTotal } from './selectors';
import { ListState, Params } from './types';

const defaultGetInitialState = <Item extends GenericItem>(): ListState<Item> => ({
  ids: [],
  items: {},
  total: 0,
  skip: 0,
  recentlyDeleted: {},
});

export const createListSlice = <
  Name extends string,
  Item extends GenericItem,
  GetListQueryParams extends { limit?: number } | undefined = { limit?: number }
>(
  params: Params<Name, Item>
) => {
  const { sliceName, getInitialState = defaultGetInitialState } = params ?? {};

  type ParameterisedDataProvider = DataProvider<Item, GetListQueryParams>;

  let dataProvider: ParameterisedDataProvider | null = null;

  const unconfiguredDataProviderError = new Error(
    `DataProvider for ${sliceName} slice is not configured, you must configure it before slice usage`
  );

  const configureDataProvider = (configuredDataProvider: ParameterisedDataProvider) => {
    dataProvider = configuredDataProvider;
  };

  const createItem = createAsyncThunk<Item, { item: Omit<Item, '_id'> }>(
    `${sliceName}/createItem`,
    async ({ item }) => {
      if (!dataProvider) {
        throw unconfiguredDataProviderError;
      }

      return await dataProvider.createItem(item);
    }
  );

  const fetchItem = createAsyncThunk<Item, { id: string }>(
    `${sliceName}/fetchItem`,
    async ({ id }, { getState }) => {
      const cachedItem = selectItemById<Item>(
        sliceName,
        id
      )(getState() as { [key: string]: ReturnType<typeof getInitialState> });

      if (cachedItem) {
        return cachedItem;
      }

      if (!dataProvider) {
        throw unconfiguredDataProviderError;
      }

      return await dataProvider.getItem(id);
    }
  );

  const updateItem = createAsyncThunk<Item, { item: Item }>(
    `${sliceName}/updateItem`,
    async ({ item }) => {
      if (!dataProvider) {
        throw unconfiguredDataProviderError;
      }

      return await dataProvider.updateItem(item);
    }
  );

  const deleteItem = createAsyncThunk<Item & { deletedAt: string }, { id: string }>(
    `${sliceName}/deleteItem`,
    async ({ id }) => {
      if (!dataProvider) {
        throw unconfiguredDataProviderError;
      }

      return await dataProvider.deleteItem(id);
    }
  );

  const undeleteItem = createAsyncThunk<Item, { id: string }>(
    `${sliceName}/undeleteItem`,
    async ({ id }) => {
      if (!dataProvider) {
        throw unconfiguredDataProviderError;
      }

      return await dataProvider.undeleteItem(id);
    }
  );

  const fetchList = createAsyncThunk<ListResponse<Item>, { params: GetListQueryParams }>(
    `${sliceName}/fetchList`,
    async ({ params: requestParams }, { getState }) => {
      const state = getState() as { [key: string]: ReturnType<typeof getInitialState> };

      if (!dataProvider) {
        throw unconfiguredDataProviderError;
      }

      return await dataProvider.getList({
        ...requestParams,
        skip: state[sliceName].skip,
      });
    }
  );

  const slice = createSlice({
    name: sliceName,
    initialState: getInitialState(),
    reducers: {
      nextPage: (state, action: PayloadAction<number>) => {
        state.skip += action.payload;
      },
      resetState: () => {
        return getInitialState();
      },
      resetList: (state) => {
        return { ...getInitialState(), items: state.items };
      },
    },
    extraReducers: (builder) => {
      builder.addCase(fetchList.fulfilled, (state, { payload }) => {
        const map = payload.data.reduce<Record<string, Draft<Item>>>((acc, item) => {
          acc[item._id] = item as Draft<Item>;

          return acc;
        }, {});

        const newIds = Object.keys(map);

        state.ids = [...state.ids.filter((id) => !newIds.includes(id)), ...newIds]; // remove items if they were loaded before loaded
        state.items = { ...state.items, ...map };
        state.total = payload.total;
      });

      builder.addCase(createItem.fulfilled, (state, { payload }) => {
        if (state.ids.length === state.total) {
          state.items[payload._id] = payload as Draft<Item>;
          state.ids.push(payload._id);
          state.total += 1;
        }
      });

      builder.addCase(fetchItem.fulfilled, (state, { payload }) => {
        // cache item, but do not update list and total value since we do not know correct order
        state.items[payload._id] = payload as Draft<Item>;
      });

      builder.addCase(updateItem.fulfilled, (state, { payload }) => {
        if (state.items[payload._id]) {
          state.items[payload._id] = payload as Draft<Item>;
        }
      });

      builder.addCase(deleteItem.fulfilled, (state, action) => {
        const idx = state.ids.indexOf(action.payload._id);

        delete state.items[action.payload._id];

        if (idx === -1) {
          return;
        }

        // delete item
        state.ids.splice(idx, 1);
        // save to restore
        state.recentlyDeleted[action.payload._id] = idx;
        // update cursor
        state.skip = idx;
        state.total = state.total > 0 ? state.total - 1 : 0;
      });

      builder.addCase(undeleteItem.fulfilled, (state, { payload: item }) => {
        if (item._id in state.recentlyDeleted) {
          state.ids.splice(state.recentlyDeleted[item._id], 0, item._id);

          state.items[item._id] = item as Draft<Item>;
          state.total += 1;

          delete state.recentlyDeleted[item._id];
        }
      });
    },
  });

  const useInfiniteList = (params?: GetListQueryParams) => {
    const { limit = 20 } = params ?? {};

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const memoizedParams = useMemo(
      () => params ?? ({ limit } as GetListQueryParams),
      // to avoid redundant re-renders - validate if params really changed
      // eslint-disable-next-line
      [JSON.stringify(params)]
    );

    const loadData = useCallback(async () => {
      try {
        setIsLoading(true);
        await dispatch(fetchList({ params: memoizedParams }));
      } finally {
        setIsLoading(false);
      }
    }, [dispatch, memoizedParams]);

    const loadNext = useCallback(() => {
      dispatch(slice.actions.nextPage(limit));
      loadData();
    }, [dispatch, limit, loadData]);

    const revalidate = useCallback(async () => {
      dispatch(slice.actions.resetList());
      await loadData();
    }, [dispatch, loadData]);

    useEffect(() => {
      revalidate();
      // automatically revalidate on args change
    }, [revalidate, memoizedParams]);

    const data = useSelector(selectResult<Item>(sliceName));

    const total = useSelector(selectTotal<Item>(sliceName));

    const skip = useSelector(selectSkip<Item>(sliceName));

    return {
      data,
      total,
      isLoading,
      loadNext,
      revalidate,
      skip,
      hasMore: skip + limit < total,
    };
  };

  const useCreateItem = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState('');
    const item = useSelector(selectItemById<Item>(sliceName, id));

    const onCreate = useCallback(
      async (item: Omit<Item, '_id'>) => {
        try {
          setId('');
          setIsLoading(true);
          const dispatchResult = await dispatch(createItem({ item }));
          const result = await unwrapResult(
            dispatchResult as unknown as UnwrappableAction<Item>
          );

          setId(result._id);

          return result;
        } finally {
          setIsLoading(false);
        }
      },
      [dispatch]
    );

    return { onCreate, isLoading, item };
  };

  const useGetItem = (params: { id?: string | null }) => {
    const memoizedParams = useMemo(
      () => params,
      // to avoid redundant re-renders - validate if params really changed
      // eslint-disable-next-line
        [JSON.stringify(params)]
    );

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const item = useSelector(selectItemById<Item>(sliceName, memoizedParams.id ?? ''));

    const onGet = useCallback(async () => {
      if (!memoizedParams.id) {
        return;
      }

      try {
        setIsLoading(true);

        await dispatch(fetchItem({ id: memoizedParams.id }));
      } finally {
        setIsLoading(false);
      }
    }, [dispatch, memoizedParams.id]);

    useEffect(() => {
      onGet();
    }, [onGet]);

    return { isLoading, item };
  };

  const useUpdateItem = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onUpdate = useCallback(
      async (item: Item) => {
        try {
          setIsLoading(true);
          const dispatchResult = await dispatch(updateItem({ item }));

          return await unwrapResult(dispatchResult as unknown as UnwrappableAction<Item>);
        } finally {
          setIsLoading(false);
        }
      },
      [dispatch]
    );

    return { onUpdate, isLoading };
  };

  const useDeleteItem = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onDelete = useCallback(
      async (id: string) => {
        try {
          setIsLoading(true);
          await dispatch(deleteItem({ id }));
        } finally {
          setIsLoading(false);
        }
      },
      [dispatch]
    );

    return { onDelete, isLoading };
  };

  const useUndeleteItem = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const onUndelete = useCallback(
      async (id: string) => {
        try {
          setIsLoading(true);
          await dispatch(undeleteItem({ id }));
        } finally {
          setIsLoading(false);
        }
      },
      [dispatch]
    );

    return { onUndelete, isLoading };
  };

  const asyncActions = {
    fetchList,
    create: createItem,
    fetch: fetchItem,
    update: updateItem,
    delete: deleteItem,
    undelete: undeleteItem,
  };

  const hooks = {
    infiniteList: useInfiniteList,
    create: useCreateItem,
    get: useGetItem,
    update: useUpdateItem,
    delete: useDeleteItem,
    undelete: useUndeleteItem,
  };

  return {
    slice,
    configureDataProvider,

    // thunks
    ...getNamedObject(asyncActions, undefined, sliceName),
    // hooks
    ...getNamedObject(hooks, 'use', sliceName),
  };
};
