import { ColumnId } from '../moosa-store/datasetCommon';

export enum DTNodeType {
  Property = 'Property',
  And = 'And',
  Or = 'Or',
  ALN = 'AtLeastN',
  AMX = 'AtMostX',
  Chart = 'Chart',
  Group = 'Group',
}

export type DTNodeId = string;

export type DTArrowId = string;

export enum DTOperation {
  Greater = '>',
  Less = '<',
  GreaterEqual = '≥',
  LessEqual = '≤',
}

export enum ConditionType {
  Simple = 'simple',
  Range = 'range',
  Discrete = 'discrete',
}

export enum PortGroupingType {
  Regular = 'regular',
  TrueMissing = 'trueMissing',
  FalseMissing = 'falseMissing',
  HideMissing = 'hideMissing',
}

export interface CommonCondition {
  type: ConditionType;
}

export interface SimpleCondition extends CommonCondition {
  type: ConditionType.Simple;
  threshold: number;
  operation: DTOperation;
}

export interface RangeCondition extends CommonCondition {
  type: ConditionType.Range;
  min: {
    threshold: number;
    operation: DTOperation.Less | DTOperation.LessEqual;
  };
  max: {
    threshold: number;
    operation: DTOperation.Less | DTOperation.LessEqual;
  };
}

export interface DiscreteCondition extends CommonCondition {
  type: ConditionType.Discrete;
  values: DiscreteValue[];
}

export interface DiscreteValue {
  value: string;
  isSelected: boolean;
}

export type Condition = SimpleCondition | RangeCondition | DiscreteCondition;

export interface DecisionTreeCommonNode {
  id: DTNodeId;
  type: DTNodeType;
  inputArrows: DTArrowId[];
  outputArrows: {
    [slotName: string]: DTArrowId[];
  };
  group?: DTNodeId;
}

export interface DecisionTreePropertyNode extends DecisionTreeCommonNode {
  type: DTNodeType.Property;
  propertyId: ColumnId;

  condition: Condition | null;

  outputArrows: {
    yes: DTArrowId[];
    no: DTArrowId[];
    missingValues: DTArrowId[];
  };

  portGroupingType: PortGroupingType | null;
}

export interface DecisionTreeAndNode extends DecisionTreeCommonNode {
  type: DTNodeType.And;

  outputArrows: {
    combine: DTArrowId[];
  };
}

export interface DecisionTreeOrNode extends DecisionTreeCommonNode {
  type: DTNodeType.Or;

  outputArrows: {
    combine: DTArrowId[];
  };
}

export interface DecisionTreeAtLeastNNode extends DecisionTreeCommonNode {
  type: DTNodeType.ALN;
  nodeN: number;

  outputArrows: {
    combine: DTArrowId[];
  };
}

export interface DecisionTreeAtMostXNode extends DecisionTreeCommonNode {
  type: DTNodeType.AMX;
  nodeN: number;

  outputArrows: {
    combine: DTArrowId[];
  };
}

export interface DecisionTreeChartNode extends DecisionTreeCommonNode {
  type: DTNodeType.Chart;
  columnId: string | null;

  outputArrows: {
    combine: DTArrowId[];
  };
}

export interface DecisionTreeGroupNode extends DecisionTreeCommonNode {
  type: DTNodeType.Group;
}

export type DecisionTreeNode =
  | DecisionTreePropertyNode
  | DecisionTreeAndNode
  | DecisionTreeOrNode
  | DecisionTreeChartNode
  | DecisionTreeAtLeastNNode
  | DecisionTreeAtMostXNode
  | DecisionTreeGroupNode;

export function isPropertyNode(node: DecisionTreeNode): node is DecisionTreePropertyNode {
  return node.type === DTNodeType.Property;
}

export function isOrNode(node: DecisionTreeNode): node is DecisionTreeOrNode {
  return node.type === DTNodeType.Or;
}

export function isAndNode(node: DecisionTreeNode): node is DecisionTreeAndNode {
  return node.type === DTNodeType.And;
}

export function isAtLeastNNode(node: DecisionTreeNode): node is DecisionTreeAtLeastNNode {
  return node.type === DTNodeType.ALN;
}

export function isAtMostXNode(node: DecisionTreeNode): node is DecisionTreeAtMostXNode {
  return node.type === DTNodeType.AMX;
}

export function isChartNode(node: DecisionTreeNode): node is DecisionTreeChartNode {
  return node.type === DTNodeType.Chart;
}

export function isGroupNode(node: DecisionTreeNode): node is DecisionTreeGroupNode {
  return node.type === DTNodeType.Group;
}

export interface DecisionTreeArrow {
  id: DTArrowId;
  from: DTNodeId;
  to: DTNodeId;
  group?: DTNodeId;
}

export interface DecisionTreeErrors {
  nodeErrors: Record<DTNodeId, string[]>;
  arrowErrors: Record<DTArrowId, string[]>;
}

export interface DecisionTree {
  nodes: DecisionTreeNode[];
  arrows: DecisionTreeArrow[];
}
